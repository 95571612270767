import React, { createContext, useEffect, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import { actions } from './reducer'

const appSiteId = parseInt(process.env.REACT_APP_SITE_ID);

const persistedState = localStorage.getItem(`persistedState-${appSiteId}`)
  ? JSON.parse(window.localStorage[`persistedState-${appSiteId}`])
  : undefined;

let AppContext = createContext(initialState);

function AppContextProvider(props) {
    const fullInitialState = {
        ...initialState,
        ...persistedState
    };
  
    let [state, dispatch] = useReducer(reducer, fullInitialState);
    let value = { 
        state, 
        dispatch,
        saveOrder: (order) => {
            dispatch({type: actions.SAVE_ORDER, order: order })
        },
        saveOrderId: (orderId) => {
            dispatch({type: actions.SAVE_ORDER_ID, orderId: orderId })
        },
        addItemToCart: (cartItem) => {
            dispatch({ type: actions.ADD_ITEM_TO_CART, cartItem });
        },
        removeItemFromCart: (cartItemId) => {
            dispatch({ type: actions.REMOVE_ITEM_FROM_CART, cartItemId });
        },
        resetCart: (initial) => {
            dispatch({ type: actions.RESET_CART, initial });
        },
        closeCart: () => {
            dispatch({ type: actions.CLOSE_CART });
        },
        setLanguage: () => {
            dispatch({ type: actions.SET_LANGUAGE });
        }
    };

    useEffect(() => {
        window.localStorage[`persistedState-${appSiteId}`] = JSON.stringify({
            order: state.order,
            orderId: state.orderId,
            cartTotal: state.cartTotal,
            cartDetails: state.cartDetails,
            languageCode: state.languageCode
        });
    }, [state]);

    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };