import React, { useState} from 'react';
import { accountService, alertService } from '../../_services'
import { Dialog } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';

const appSiteId = parseInt(process.env.REACT_APP_SITE_ID);

function RegisterForm({onClose, goLogin}) {
    const [registerProfile, setRegisterProfile] = useState({
        title: 'Mr',
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: true
    });    
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage] = useState('');

    const { t } = useTranslation();

    const setField = (fieldName, value) => {
        var _userName = registerProfile.userName;
        if (fieldName === 'email')
            _userName = value;
        setRegisterProfile({
            ...registerProfile,
            userName: _userName,
            [fieldName]: value
        })        
    }

    function onSubmit() {    

        accountService.register(registerProfile, appSiteId)
            .then(() => {
                alertService.success(t('account.registerForm.msg1'), { keepAfterRouteChange: true });
                setMessageClass('bg-green-200')
                setMessage(t('account.registerForm.msg1'))    
                //goLogin()
            })
            .catch(error => {
                alertService.error(t('account.registerForm.msg2'), { keepAfterRouteChange: true });
                setMessageClass('bg-red-200')
                setMessage(t('account.registerForm.msg2'))
            });
    }

    return (
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <Dialog.Title as="h3" className="text-lg text-center font-semibold">
                    {t('account.registerForm.title')}
                </Dialog.Title>
            </div>
            <div className="bg-white px-4 pb-2 sm:p-6 sm:pb-4">                   
                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">            
                    {message === '' &&
                    <form onSubmit={() => onSubmit()}>
                        <p className="text-sm text-gray-500">
                        {t('account.registerForm.subTitle')}
                        </p>
                        {/* <div className='flex-col'>
                            <label>Titolo</label>
                            <select name="title" className='form-control'>
                                <option value=""></option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                            </select>                                
                        </div> */}
                        <div className="form-group">
                            <input name="firstName" placeholder={t('account.registerForm.field1')} type="text" value={registerProfile.firstName} onChange={(e) => setField('firstName',e.target.value)} className='form-control' />
                        </div>
                        <div className="form-group">
                            <input name="lastName" placeholder={t('account.registerForm.field2')} type="text" value={registerProfile.lastName} onChange={(e) => setField('lastName',e.target.value)} className='form-control' />                                
                        </div>
                        <div className="form-group">
                            <input name="email" placeholder={t('account.registerForm.field3')} type="text" value={registerProfile.email} onChange={(e) => setField('email',e.target.value)} className='form-control' />                                
                        </div>
                        {/* <div className="form-group">
                            <label>Username</label>
                            <input name="userName" type="text" value={registerProfile.userName} onChange={(e) => setField('userName',e.target.value)} className='form-control' />                                
                        </div> */}
                        <div className="form-group">
                            <input name="password" placeholder={t('account.registerForm.field4')} type="password" value={registerProfile.password} onChange={(e) => setField('password',e.target.value)} className='form-control' />                                
                        </div>
                        <div className="form-group">
                            <input name="confirmPassword" placeholder={t('account.registerForm.field5')} type="password" value={registerProfile.confirmPassword} onChange={(e) => setField('confirmPassword',e.target.value)} className='form-control' />                                
                        </div>
                        {/* <div className="form-group form-check">
                            <input type="checkbox" name="acceptTerms" id="acceptTerms" value={registerProfile.acceptTerms} onChange={(e) => setField('acceptTerms',e.target.value)} className={'form-check-input '} />
                            <label htmlFor="acceptTerms" className="form-check-label">Accetta i termini</label>                            
                        </div> */}
                        
                    </form>}
                </div>                
            </div>
            {message === '' &&
            <div className='flex px-4'>
                <button type="button"
                    className="flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    onClick={() => onSubmit()}>
                        {t('account.registerForm.action1')}
                </button>
            </div>}
            <div className={`m-3 rounded-md overflow-hidden p-2 font-bold text-center ${messageClass}`}>
                <p>{message}</p>
            </div>
            <div className='mt-1 px-4 py-1 text-gray-500 text-xs text-center'>
                <p>{t('account.registerForm.note')}</p>
            </div>                    
            <div className="flex bg-gray-200 px-4 py-3 sm:px-6 flex-col sm:flex-row-reverse space-y-1">                
                <button type="button"
                    className="flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 sm:text-sm"
                    onClick={() => goLogin()}>
                        {t('account.registerForm.action2')}
                </button>
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 sm:text-sm"
                    >
                    {t('account.registerForm.action3')}
                </button>
            </div>
        </div>
    )
}

export { RegisterForm }; 