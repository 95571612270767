import React, { useState } from 'react'
import { accountService, alertService } from '../../_services'
import { UserIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';

function LoginForm({onClose,goRegister,goForgotPassword}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [message, setMessage] = useState('');

    const { t } = useTranslation();

    const onLogin = () => {
        accountService.login(email, password)
            .then(() => {
                alertService.success('Login successful', { keepAfterRouteChange: true });
                //setMessage('')
                onClose()
            })
            .catch(error => {
                alertService.error('Invalid login, check your credentials.', { keepAfterRouteChange: true });
                //setMessage('Login non riuscito :-(')
            });
    }

    const onLogout = () => {
        accountService.logout()
        alertService.info('Utente disconnesso.', { keepAfterRouteChange: true });
        onClose()
    }

    return (
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <h3 className="text-lg text-center font-semibold">
                    {t('account.loginForm.title')}
                </h3>
            </div>

            <div className="bg-white px-4 pb-2 sm:p-6 sm:pb-4">                   
                {accountService.userValue && 
                <div className='mt-3'>
                    {t('account.loginForm.subTitle')}
                    <b className='ml-1'>{accountService.userValue.email}</b>
                </div>}
                {accountService.userValue == null && 
                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <form onSubmit={() => onLogin()}>                        
                        <p className="text-sm text-gray-500">
                        {t('account.loginForm.note')}
                        </p>

                        <div className="form-group">
                            <input name="email" placeholder='Email' onChange={(e) => setEmail(e.target.value)} type="text" className={'form-control'} />
                        </div>
                        <div className="form-group">
                            <input name="password" placeholder='Password' type="password" onChange={(e) => setPassword(e.target.value)} className={'form-control'} />                                
                        </div>                                                                                        
                    </form>
                </div>
                }
            </div>
            {accountService.userValue == null && 
            <div className='flex px-4'>
                <button type="button"
                    className="flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    onClick={() => onLogin()}>
                        {t('account.loginForm.action1')}
                </button>
            </div>}
            <div className='mt-1 text-center text-red-500'>
                <p>{message}</p>
            </div>
            {accountService.userValue == null && 
            <div className='mt-1 px-4 py-1 text-gray-500 text-xs text-center'>
                <p>{t('account.loginForm.note2')}</p>
            </div>}
            <div className="flex bg-gray-200 px-4 py-3 flex-col sm:flex-row-reverse space-y-1">
                {accountService.userValue == null && 
                <>
                <button type="button"
                    className="flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 sm:text-sm"
                    onClick={() => goForgotPassword()}>
                        {t('account.loginForm.action2')}
                </button>
                <button type="button"
                    className="flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 sm:text-sm"
                    onClick={() => goRegister()}>
                        {t('account.loginForm.action3')}
                </button>
                </>}
                {accountService.userValue != null && 
                <button type="button"
                    className="flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:flex-2 sm:text-sm"
                    onClick={() => onLogout()}>
                        {t('account.loginForm.action4')}
                </button>}
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 sm:text-sm"
                    >
                    {t('account.loginForm.action5')}
                </button>
            </div>
        </div>
    );
}

export { LoginForm }