import React from 'react';
import { Container, Card, Image, Row, Col } from 'react-bootstrap';
import { appSiteService } from '../../_services';
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

class EmployeeList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            employees: null,
            appSiteId: this.props.appSiteId,
            sitePageId: this.props.sitePageId,
            pageBoxId: this.props.pageBoxId
        }        
    }

    componentDidMount() {
        this.getEmployees(this.props.appSiteId, this.props.sitePageId, this.props.pageBoxId);
    }

    getEmployees(appSiteId, sitePageId, pageBoxId) {            
        appSiteService.getEmployeesOfBox(appSiteId, sitePageId, pageBoxId)
            .then((_employees) => {
                this.setState({ employees: (_employees.totalCount > 0 ? _employees.result : []) });                                                                
            })
            .catch(() => {});        
    }

    render() {
        return (
            <Container className="employee">
                <Row>                
                {this.state.employees && this.state.employees.map(employee =>                                    
                    <Col sm={parseInt(employee.cardSize)} key={employee.employeeId}>
                        <div>
                            <Card className="mart2">
                                <Card.Body>
                                    <Card.Title>
                                        <small>{employee.title}</small><br />
                                        {employee.firstName} {employee.lastName}
                                    </Card.Title>                            
                                    <Row className="justify-content-md-center">
                                        <Col sm={4}>
                                            <Image src={baseImageUrl+employee.imageUrl} roundedCircle fluid />
                                        </Col>
                                    </Row>                                                                
                                    <hr className="divider" />
                                    {employee.description && parse(employee.description)}
                                </Card.Body>
                            </Card>   
                        </div>                        
                    </Col>                                         
                )}                    
                {!this.state.employees &&                
                    <Col className="text-center">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </Col>
                }        
                </Row>        
            </Container>
        );
    }

}

export { EmployeeList };