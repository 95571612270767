import React from 'react';
import parse from 'html-react-parser';

function BoxDescription(props) {    

    return (
        <>
            {props.landingPageId > 0 && 
            <a href={`/${props.landingPageId}`}                
                className={`description hover:no-underline ${(props.shape === 2 ? 'rounded-full' : (props.shape === 3 ? 'rounded-xl' : ''))}`}
                // onClick={() => {
                //     if (props.landingPageId > 0)
                //         props.changePage(props.landingPageId)
                // }}
                >            
                {props.title && <div>{parse(props.title)}</div>}
                {props.description && parse(props.description)}
            </a>}

            {props.landingPageId == null && 
            <div className={`description ${(props.shape === 2 ? 'rounded-full' : (props.shape === 3 ? 'rounded-xl' : ''))}`}>            
                {props.title && parse(props.title)}
                {props.description && parse(props.description)}
            </div>}
        </>
    );
}

export { BoxDescription };