import React, {useContext} from 'react';
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { accountService } from '../../_services';
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { AppContext } from '../../_data/shop/state'
import { useTranslation } from 'react-i18next'

const CheckoutForm = ({orderId,cartTotal,cartDetails,goCheckout,optionalPay,onClose}) => {
  const stripe = useStripe();
  const elements = useElements();

  const { state,saveOrder,saveOrderId } = useContext(AppContext);
  
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    
    
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // SISTEMARE STRIPE ELEMENTS !!! è sempre undefined
    // const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/-4?orderId=${orderId}`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  
  return (
    <div className="inline-block align-bottom h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full">
      <div className='pt-4 pb-2 bg-gray-200'>
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <ShoppingCartIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>                
          <h3 className="text-lg text-center font-semibold">
          {t('shop.checkoutForm.title')}
          </h3>
      </div>  
      <div className="h-full static overflow-y-auto pb-48 bg-white px-4">                                                   
        
        <form onSubmit={handleSubmit}>
          
          <div className='border sticky rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm text-black font-large text-center'>
            <p>{t('shop.checkoutForm.order')} {state.order.orderId}-{state.order.orderYear}</p>
            <p><b className='text-xl'>€ {cartTotal}</b><br /> ({cartDetails.length} {t('shop.checkoutForm.items')})</p>
          </div>

          {accountService.userValue && 
            <div className='border rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm text-black font-large text-center'>                    
                <b>{accountService.userValue.email}</b>
            </div>}   

          <div className='border rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm text-black font-large text-center'>
            <PaymentElement />
          </div>

          {optionalPay && 
          <div className='mt-3 px-4 py-2 bg-gray-50 text-center'>
            <a href={`/-4?orderId=${state.order.orderYear}-${state.order.orderId}&payment_intent=ONDELIVERY&payment_intent_client_secret=`}
              className="flex-1 flex items-center justify-center rounded-md border border-transparent shadow-sm px-2 py-1 bg-gray-200 font-small text-gray-400 hover:bg-gray-200 hover:no-underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 text-sm">    
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                {t('shop.checkoutForm.action1')}
            </a>
          </div>}

        </form>
      </div>

      <div className='absolute bottom-12 md:bottom-20 p-2 w-full bg-gray-200 space-x-2 text-center h-18'>
        <div className='w-full text-center'>
          <button 
            onClick={handleSubmit}
            type='submit'       
            disabled={!stripe}
            className="rounded-md border w-full flex items-center border-transparent shadow-sm px-4 py-2 bg-yellow-400 justify-center font-medium text-black hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-lg">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {t('shop.checkoutForm.action2')}
          </button>                
        </div>
      </div>            

      <div className="absolute bottom-0 md:bottom-8 p-2 w-full bg-gray-200  space-x-2 flex text-center h-18">
          <button type="button"
              className="flex-1 flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 text-sm"
              onClick={() => goCheckout()}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                  {t('shop.checkoutForm.action3')}
          </button>
          <button 
              type="button"
              onClick={() => onClose()}
              className="flex-1 flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 text-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
                {t('shop.checkoutForm.action4')}
          </button>
      </div>
    </div>
  );
}

export { CheckoutForm }