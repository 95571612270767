// EDIT MULTI LANGUAGE 01/2021
import { fetchWrapper } from '../_helpers';
import { BehaviorSubject } from 'rxjs';

//import { config } from '../config.json'
// export const config = require("../config.json");
// const baseUrl = `${config.REACT_APP_API_URL}/PublicAppSite`;
const baseUrl = `${process.env.REACT_APP_API_URL}/PublicAppSite`;
const langSubject = new BehaviorSubject('_');


export const languageService = {
    getlanguagesOfSite,
    setLanguage,
    lang: langSubject.asObservable(),   
    get langValue () { return langSubject.value }
};

function getlanguagesOfSite(appSiteId) {
    return fetchWrapper.get(`${baseUrl}/SearchLanguagesOfAppSite/${appSiteId}`);
}

function setLanguage(langCode) {
    langSubject.next(langCode);
}