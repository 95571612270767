import React, { useEffect, useState } from 'react';
import { surveyService } from '../../_services';
import parse from 'html-react-parser';
import { SiteSurveyBoxQuestion } from './SiteSurveyBoxQuestion';

function SiteSurveyBoxStep ({appSiteId, siteSurveyId, surveyStepId, surveyStep, countQty, addUserAnswer, userAnswers, handleValid}) {
    
    const [stepQuestions, setStepQuestions] = useState([])
    //const [countUserAnswers, setCountUserAnswers] = useState(0)
    const [isValid, setIsValid] = useState(false)
    const [countStep, setCountStep] = useState(0)

    useEffect(() => {
        surveyService.getQuestionsOfStep(appSiteId,siteSurveyId,surveyStepId)
            .then((x) => setStepQuestions(x.result));
    },[appSiteId, siteSurveyId, surveyStepId])        

    const handleAddUserAnswer = (_userAnswer, _stepQuestion, _action, _nextStepId, _qtyAct) => {


        addUserAnswer(_userAnswer, _stepQuestion, _action, _nextStepId, _qtyAct)
    }

    useEffect(() => {
        if (stepQuestions == null)
            return false;
        
        var _isValid = true        
        stepQuestions.forEach(_stepQuestion => {
            if (!_stepQuestion.isValid)
                _isValid = false
        });

        // Filter answers of current question
        var activeAnswers = userAnswers.filter(_userAnswer => {
            return (_userAnswer.siteSurveyId === siteSurveyId &&
                _userAnswer.surveyStepId === surveyStepId)
        })

        // Count answers
        var _count = 0;
        activeAnswers.forEach((userAnswer) => _count += (userAnswer.quantity > 0 ? userAnswer.quantity : 1));

        // NOT VALID
        // Check valid min and max answers         
        if (surveyStep.surveyStepId > 0 && 
            (surveyStep.minAnswers >= _count && surveyStep.minAnswers > 0) &&
            (surveyStep.maxAnswers <= _count && surveyStep.maxAnswers > 0)) 
            _isValid = false;
    
        setCountStep(_count)
        setIsValid(_isValid)
        handleValid(_isValid)
        //setCountUserAnswers(activeAnswers.length)
    }, [stepQuestions, userAnswers])
    
    return (
        <div style={{backgroundColor: surveyStep.boxColor}} className={`${(isValid ? '' : '')}`}>
            <ul>
                {stepQuestions && stepQuestions.length > 0 && stepQuestions.map(stepQuestion =>
                <li key={stepQuestion.stepQuestionId} className={`static rounded-xl`}>
                    <div className='flex items-center sticky top-0 h-16 drop-shadow-sm z-10' 
                        style={{backgroundColor: stepQuestion.boxColor}}>                        
                        <div className='flex-1'>
                            {stepQuestion.questionText && parse(stepQuestion.questionText)}
                        </div>
                        {!stepQuestion.isOptional &&
                        <div className={`flex flex-none ${(stepQuestion.isValid ? 'bg-green-700' : 'bg-gray-700')} rounded-xl m-1 mr-2 text-xs overflow-hidden`}>
                            <div className={`${(stepQuestion.isValid ? 'bg-green-900' : 'bg-gray-500')} pl-1 pr-1 pt-1`}>
                                {(stepQuestion.minAnswers - stepQuestion.count) > 0 &&  
                                <label className='text-gray-50'>{stepQuestion.minAnswers - stepQuestion.count}</label>}
                            </div>
                            <div className='pl-1 pr-1 pt-1'>
                                <label className='text-white'>obbligatori{stepQuestion.maxAnswers === 1 ? 'o' : ''}</label>
                            </div>
                        </div>}
                    </div>
                    <SiteSurveyBoxQuestion 
                        appSiteId={appSiteId} 
                        siteSurveyId={siteSurveyId} 
                        surveyStepId={surveyStepId} 
                        stepQuestionId={stepQuestion.stepQuestionId}
                        stepQuestion={stepQuestion}
                        surveyStep={surveyStep}
                        countQty={countStep}
                        userAnswers={userAnswers}
                        handleValid={(isValid) => stepQuestion.isValid = isValid}
                        handleCount={(count) => stepQuestion.count = count}
                        addUserAnswer={(_userAnswer, _stepQuestion, _action, _nextStepId, _qtyAct) => handleAddUserAnswer(_userAnswer, _stepQuestion, _action, _nextStepId, _qtyAct)} />
                    
                    {stepQuestion.questionNote && 
                    <div className="mt-1">
                        {parse(stepQuestion.questionNote)}
                    </div>}
                </li>)}
            </ul>
        </div>
    )
}

export { SiteSurveyBoxStep };