import { fetchWrapper } from '../_helpers';

//import { config } from '../config.json'
// export const config = require("../config.json");
// const baseUrl = `${config.REACT_APP_API_URL}/contact`;
const baseUrl = `${process.env.REACT_APP_API_URL}/contact`;

export const contactService = {
    sendForm
}

function sendForm(appSiteId, sitePageId, pageBoxId, from, subject, body) {
    return fetchWrapper.post(`${baseUrl}/SendForm`, { 
        appSiteId: appSiteId,
        sitePageId: sitePageId,
        pageBoxId: pageBoxId,
        emailFrom: from, 
        subject: subject, 
        bodyMessage: body });   
}