import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
  } from "react-google-maps";
  
const Maps = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={18}
        defaultCenter={{ lat: 45.87960769, lng: 8.41128271 }}
    >
        {props.isMarkerShown && <Marker position={{ lat: 45.87960769, lng: 8.41128271 }} />}
    </GoogleMap>
));

export { Maps }