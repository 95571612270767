//import React from 'react';

const appSiteId = parseInt(process.env.REACT_APP_SITE_ID);

//Initial State and Actions
export const initialState = {
    cartDetails: [],
    cartTotal: 0,
    orderId: '',
    order: {
        appSiteId: appSiteId,
        orderYear: 0,
        orderId: 0,
        orderType: 1,
        orderTotal: 0        
    },
    languageCode: '_'
};

export const actions = {
    SAVE_ORDER: "saveOrder",
    SAVE_ORDER_ID: "saveOrderId",
    ADD_ITEM_TO_CART: "addItemToCart",
    REMOVE_ITEM_FROM_CART: "removeItemFromCart",
    RESET_CART: "resetCart",
    CLOSE_CART: "closeCart",
    SET_LANGUAGE: "setLanguage"
};

export const reducer = (state, action) => {    
    var total = 0;
    switch (action.type) {
        case actions.SAVE_ORDER:
            return {...state, order: action.order }
        case actions.SAVE_ORDER_ID:
            return {...state, orderId: action.orderId }
        case actions.ADD_ITEM_TO_CART:
            total += state.cartTotal + (action.cartItem.price * action.cartItem.quantity);
            var _item = state.cartDetails
                //.filter((_item) => _item.siteProductId === action.cartItem.siteProductId);
                .filter((_item) => _item.productId === action.cartItem.productId);
            if (_item.length === 0)
                return {...state, cartDetails: [...state.cartDetails, action.cartItem], cartTotal: total};
            // Add 
            _item[0].quantity += action.cartItem.quantity;
            // New list without update row
            var _cartDetails = state.cartDetails
                //.filter((_item) => _item.siteProductId !== action.cartItem.siteProductId);                        
                .filter((_item) => _item.productId !== action.cartItem.productId);                        

            return {...state, cartDetails: [..._cartDetails, _item[0]], cartTotal: total};
        case actions.REMOVE_ITEM_FROM_CART:                     
            _cartDetails = [...state.cartDetails.filter((det) => det.productId !== action.cartItemId)];
            if (_cartDetails){
                _cartDetails.forEach(element => {
                    total += element.price * element.quantity;
                });                
            }
            return {...state, cartDetails: _cartDetails, cartTotal: total};
        case actions.RESET_CART:
            return {...state, cartDetails: [], cartTotal: 0};
        case actions.CLOSE_CART:
            //return initialState;
            return { 
                cartDetails: [],
                cartTotal: 0,
                orderId: '',
                order: {
                    appSiteId: appSiteId,
                    orderYear: 0,
                    orderId: 0,
                    orderType: 1,
                    orderTotal: 0        
                }
             }
        case actions.SET_LANGUAGE:
            return {...state, languageCode: action.languageCode }
        default:
            return state;
    }
}

export default reducer;