import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import parse from 'html-react-parser';
import { Navbar } from "react-bootstrap";
import { LanguageSelect } from './LanguageSelect';
import { ProfileModal } from '../_components/Account/ProfileModal'
import { SearchDialog } from './SearchDialog/SearchDialog';
import { ShopCart } from "./Shop/ShopCart";
import { HomeIcon } from '@heroicons/react/outline';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function Footer({appSiteId, appSite, navColor, footerColor, activeProfileModal, activeShopModal, stripePublicKey}) {   

    const [footerHidden, setFooterHidden] = useState(window.innerWidth > 768)

    useEffect(() => {
        // Add event listener
        window.addEventListener("resize", () => setFooterHidden(window.innerWidth > 768));
        
    }, [])
    
    return (
        <>            
            {appSite &&
            <div className="relative footer shadown-lg border-t mt-3" style={{backgroundColor: footerColor}}>     
                <div className="text-center mt-2 ml-5 mr-5 mb-16 pb-20 md:pb-12 md:mb-0">
                    {appSite.companyLogo && 
                    <Image className="company-logo h-12 md:h-16 mx-auto mb-2" src={baseImageUrl+appSite.companyLogo}></Image>}
                    <h3>{appSite.name}</h3>
                    <Row className="justify-center">                        
                        <Col xs={12} md={2} className="mart2">                            
                            <p className='md:justify-start md:text-left'>
                                {appSite.address}<br />
                                {appSite.city} {appSite.postalCode}<br />
                                {appSite.phone}<br /> 
                                <a href={`mailto:${appSite.email}`} className='text-gray-700 hover:no-underline'>{appSite.email}</a>
                            </p>
                        </Col>
                        <Col xs={12} md={6} className="text-center mart2">
                            {appSite.description && parse(appSite.description || '<p></p>')}                            
                        </Col>
                        <Col xs={12} md={2} className="text-right mart2">
                            <LanguageSelect appSiteId={appSiteId} />                           
                        </Col>                        
                    </Row>            
                </div>                   
                <div className="absolute border-t border-t-amber-200 mt-3 flex items-center bg-gray-200 justify-center text-center h-16 md:h-12 left-0 right-0 bottom-16 md:bottom-0 w-full">
                    <p className='text-gray-500'>Copyright ©2020-2022 All rights reserved | This site is made by <a href="http://eazyweb.it" className='text-gray-700 hover:no-underline'> EasyWEB </a> |  
                    <a href="https://crm-admin.eazyweb.it" className='text-gray-700 hover:no-underline'> Admin Area </a></p>
                </div>
                <hr className="divider mt-5" />
            </div>}

            {appSite && !footerHidden &&             
                <div style={{backgroundColor: navColor}} 
                    className='fixed items-center flex bottom-0 left-0 right-0 h-16 border-t-2 md:hidden'>
                    <div className='flex-1'>
                        <div className='mx-auto w-16'>
                            <Navbar.Brand href='/' className='px-3 py-1 text-sm font-medium text-white bg-black rounded-lg bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                                <span className="relative inline-block">
                                    <HomeIcon className="h-6 w-6 m-1 text-white-600" aria-hidden="true" />
                                </span>
                            </Navbar.Brand>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className='mx-auto w-16'>
                            {appSite.loginEnabled && 
                            <ProfileModal activeProfileModal={(activeProfileModal)} />}
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className='mx-auto w-16'>
                            {appSite.shopEnabled  && 
                            <ShopCart appSiteId={appSite.appSiteId} 
                                initialCart={[]} 
                                stripePublicKey={stripePublicKey}
                                activeShopModal={activeShopModal}
                                optionalPay={appSite.optionalPay} />}
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className='mx-auto w-16'>
                            <SearchDialog appSiteId={appSite.appSiteId} />
                        </div>
                    </div>
                </div>
                }
        </>      
    );
}

export { Footer };