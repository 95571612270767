import React, { useEffect,useState } from 'react';
import { surveyService } from '../../_services';
import { SiteSurveyBoxNav } from './SiteSurveyBoxNav';
import { SiteSurveyBoxStep } from './SiteSurveyBoxStep';
import { accountService } from '../../_services'
import { AppContext } from '../../_data/shop/state';
import { Dialog } from '@headlessui/react'
import parse from 'html-react-parser';

function SiteSurveyBox ({ appSiteId, siteSurveyId, surveyStepId, stepQuestionId, userSurveyId, pageBox }) {

    const { addItemToCart } = React.useContext(AppContext);
    const [stepHistory, setStepHistory] = useState([0]);

    const [userSurvey, setUserSurvey] = useState({
        appSiteId: appSiteId,
        siteSurveyId: siteSurveyId,
        userSurveyId: userSurveyId,
        userName: accountService.userValue ? accountService.userValue.userName : ''
    })
    
    const [siteSurveyState, setSiteSurveyState] = useState({ 
        appSiteId: appSiteId,
        siteSurveyId: siteSurveyId,
        surveyStepId: surveyStepId || 1,
        selectedSurveyStep: {},
        stepQuestionId: stepQuestionId || 1,
        selectedStepQuestion: {},
        stepIsValid: false,         
        currentStep: 0,   
        nextStep: 0,
        lastStep: false,
        userAnswers: [],
        total: 0,
        count: 0 
    });

    const [siteSurvey, setSiteSurvey] = useState({});
    const [surveySteps, setSurveySteps] = useState([]);           
    const [isOpen, setIsOpen] = useState(false);    

    useEffect(() => {
        if (siteSurveyState.siteSurveyId > 0)
            surveyService.getSiteSurveyById(siteSurveyState.appSiteId,siteSurveyState.siteSurveyId)
                .then((_siteSurvey) => setSiteSurvey(_siteSurvey));
    },[siteSurveyState.appSiteId,siteSurveyState.siteSurveyId])

    useEffect(() => {
        if (siteSurveyState.siteSurveyId > 0)
            surveyService.getStepsOfSurvey(siteSurveyState.appSiteId,siteSurveyState.siteSurveyId)
                .then((x) => { 
                    setSurveySteps(x.result)
                    if (x.totalCount > 0) 
                        setSiteSurveyState({
                            ...siteSurveyState,
                            surveyStepId: x.result[0].surveyStepId,
                            selectedSurveyStep: x.result[0]
                        })                                  
                });
    },[siteSurveyState.appSiteId,siteSurveyState.siteSurveyId])    

    const addUserAnswer = (_userAnswer, _stepQuestion, _action, _nextStepId, _qtyAct) => {        

        // Count quantity answers
        var _countQty = 0;
        siteSurveyState.userAnswers.forEach((userAnswer) => _countQty += (userAnswer.quantity > 0 ? userAnswer.quantity : 1));

        // Check max answers
        if (_action && siteSurveyState.selectedSurveyStep.maxAnswers > 0 && 
            _countQty >= siteSurveyState.selectedSurveyStep.maxAnswers)
            return false;

        // Remove if already present
        var _userAnswers = siteSurveyState.userAnswers
            .filter((_answer) => !(_answer.surveyStepId === _userAnswer.surveyStepId &&
                _answer.stepQuestionId === _userAnswer.stepQuestionId && 
                (_answer.questionAnswerId === _userAnswer.questionAnswerId
                    || !_stepQuestion.multipleChoice)));                            
                
        // Add new value 
        if (_action)
            _userAnswers.push(_userAnswer);

        // Compute total €
        var _total = 0;
        _userAnswers.forEach((userAnswer) => _total += (userAnswer.price * (userAnswer.quantity > 0 ? userAnswer.quantity : 1)));
        // Count answers
        var _count = 0;
        _userAnswers.forEach((userAnswer) => _count += (userAnswer.quantity > 0 ? userAnswer.quantity : 1));
        
        // Check next step
        var selStep = (_nextStepId > 0 && _action) ? surveySteps.filter((step) => step.surveyStepId === _nextStepId) : null;                
        if (selStep != null) {
            var _selStepIndex = surveySteps.indexOf(selStep[0]);
            setStepHistory([...stepHistory, _selStepIndex])
        }

        // Set state 
        setSiteSurveyState({
            ...siteSurveyState,
            userAnswers: _userAnswers,
            total: _total,
            count: _count,
            surveyStepId: selStep ? selStep[0].surveyStepId : siteSurveyState.surveyStepId,
            selectedSurveyStep: selStep ? selStep[0] : siteSurveyState.selectedSurveyStep
        })
    }

    const handleValid = (_isValid) => {
        var _nextStep = 0, _currentStep = 0, _last = false;        
        if (surveySteps != null && surveySteps.length > 0) {
            for(var i = 0; i < surveySteps.length; i++) {
                if (siteSurveyState.surveyStepId != null && 
                    surveySteps[i].surveyStepId === siteSurveyState.surveyStepId) {
                        _currentStep = i;
                        if (_isValid && (i < (surveySteps.length - 1)))
                            _nextStep = i + 1;                
                        if (_isValid && i === (surveySteps.length - 1))
                            _last = true;
                    }                
            }
        }
        
        setSiteSurveyState({
            ...siteSurveyState,
            currentStep: _currentStep,
            stepIsValid: _isValid,
            nextStep: _nextStep,
            lastStep: _last
        })        
    }

    const handleNext = (stepId) => {                
        if (surveySteps[stepId] != null) {
            setSiteSurveyState({
                ...siteSurveyState,
                surveyStepId: surveySteps[stepId].surveyStepId,
                selectedSurveyStep: surveySteps[stepId]
            })              
            setStepHistory([...stepHistory, stepId])
        }
    }

    const handlePrev = () => {
        if (stepHistory && stepHistory.length > 0) {
            // Remove answer of current step
            var _userAnswers = siteSurveyState.userAnswers;
            if (siteSurveyState.selectedSurveyStep) 
                _userAnswers = siteSurveyState.userAnswers
                    .filter((_answer) => _answer.surveyStepId !== siteSurveyState.selectedSurveyStep.surveyStepId);                            
            
            setStepHistory(stepHistory.slice(0,stepHistory.length - 1))
            setSiteSurveyState({
                ...siteSurveyState,
                surveyStepId: surveySteps[stepHistory[stepHistory.length - 2]].surveyStepId,
                selectedSurveyStep: surveySteps[stepHistory[stepHistory.length - 2]],
                userAnswers: _userAnswers
            })  
        }
    }

    const handleEndSurvey = () => {
        surveyService.createUserSurvey(userSurvey, siteSurveyState.userAnswers)
            .then((_userSurvey) => {
                if (!_userSurvey.hasErrors){
                    var cartItem = {
                        productId: _userSurvey.userSurveyId * -1, 
                        siteSurveyId: siteSurveyState.siteSurveyId,
                        userSurveyId: _userSurvey.userSurveyId,    
                        siteSurvey: siteSurvey,                    
                        quantity: 1,
                        price: siteSurveyState.total,
                        var: 22,
                        discount: 0 
                    }
                    
                    addItemToCart(cartItem);

                    setUserSurvey({ ...userSurvey, userSurveyId: 0 })
                    setSiteSurveyState({
                        ...siteSurveyState,
                        surveyStepId: surveySteps[0].surveyStepId,
                        selectedSurveyStep: surveySteps[0],
                        total: 0,                        
                        userAnswers: []
                    })

                    setStepHistory([])
                    setIsOpen(false)
                }
            });
    }
    
    return (
        <>       
        <div className='w-full cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
            <div className='description hover:no-underline'>
                {pageBox.title && parse(pageBox.title)}
                {pageBox.description && parse(pageBox.description)}
            </div>            
        </div> 
        {siteSurveyState &&
        <Dialog as="div" className="fixed z-10 inset-0" onClose={() => setIsOpen(false)} open={isOpen}>
            <div className="static block items-end justify-center min-h-screen pt-4 pb-4 text-center sm:p-0">
                <Dialog.Overlay onClick={() => setIsOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />                        
                {siteSurvey && 
                <div className="absolute left-2 right-2 top-7 bottom-7 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">

                    <div style={{backgroundColor: siteSurvey.boxColor}} 
                        className="block relative mx-auto w-full md:w-1/2 h-full rounded-xl flex-grow overflow-hidden">
                        
                        <div className='absolute md:mt-8 top-0 right-0 text-gray-400 p-2 cursor-pointer' onClick={() => setIsOpen(false)}>                                            
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>

                        {surveySteps && surveySteps.length > 0 &&                 
                        <div className='pt-1 pb-1 h-36 shadow-lg'>
                            <SiteSurveyBoxNav                         
                                surveySteps={surveySteps} 
                                surveyStepId={siteSurveyState.surveyStepId} />
                        </div>}
                        
                        {siteSurveyState.selectedSurveyStep && 
                        <div className='h-full overflow-y-auto pb-24'>
                            <SiteSurveyBoxStep 
                                appSiteId={siteSurveyState.appSiteId} 
                                siteSurveyId={siteSurveyState.siteSurveyId} 
                                surveyStepId={siteSurveyState.surveyStepId} 
                                surveyStep={siteSurveyState.selectedSurveyStep}
                                countQty={siteSurveyState.count}
                                handleValid={(_isValid) => handleValid(_isValid)}
                                addUserAnswer={(_userAnswer,_stepQuestion, _action, _nextStepId, _qtyAct) => addUserAnswer(_userAnswer,_stepQuestion, _action, _nextStepId, _qtyAct)}
                                userAnswers={siteSurveyState.userAnswers} />
                            <div className='h-40'>
                                <hr className='divider' />
                            </div>
                        </div>}

                        <div className="absolute bottom-12 p-2 w-full bg-gray-200 flex text-center h-18 z-20">
                            {/* <ul>
                            {siteSurveyState.userAnswers && siteSurveyState.userAnswers.map(userAnswer => 
                                <li key={`${userAnswer.appSiteId}-${userAnswer.siteSurveyId}-${userAnswer.userSurveyId}-${userAnswer.surveyStepId}-${userAnswer.stepQuestionId}-${userAnswer.questionAnswerId}`}>
                                    {userAnswer.appSiteId}-{userAnswer.siteSurveyId}-{userAnswer.userSurveyId}-{userAnswer.surveyStepId}-{userAnswer.stepQuestionId}-{userAnswer.questionAnswerId}-#{userAnswer.quantity}
                                </li>
                            )}
                            </ul> */}
                            {siteSurveyState.currentStep > 0 && 
                            <div className='flex-1'>
                                <button className="w-5/6 flex items-center justify-center rounded-full p-2 m-2 mx-auto bg-gray-50 active:bg-green-700"
                                    onClick={() => handlePrev()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    Back
                                </button>
                            </div>}
                            {siteSurveyState.stepIsValid && !siteSurveyState.lastStep && 
                            <div className='flex-1'>
                                <button className="w-5/6 flex items-center justify-center rounded-full p-2 m-2 mx-auto bg-green-500 text-white font-bold active:bg-green-700"
                                    onClick={() => handleNext(siteSurveyState.nextStep)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                    Next
                                </button>
                            </div>}
                            {siteSurveyState.lastStep && 
                            <div className='flex-1'>
                                <button className="flex-1 w-5/6 flex items-center justify-center rounded-full p-2 m-2 mx-auto bg-green-500 text-white font-bold active:bg-green-700"
                                    onClick={() => handleEndSurvey()}>
                                        <span className="flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                            </svg>
                                            <span className='flex-1'>
                                                Buy
                                            </span>
                                        </span>
                                </button>
                            </div>}
                        </div>
                        
                        <div className='flex items-center justify-center absolute bottom-0 h-12 w-full rounded-b-xl overflow-hidden text-center mt-4 pb-2 pt-2 bg-gray-300 z-20'>
                            <b className='text-lg text-gray-500 mr-2'>Total <span className='text-black'> € {siteSurveyState.total}</span></b> 
                            (items {siteSurveyState.count} {siteSurveyState.selectedSurveyStep.maxAnswers > 0 ? '/ ' + siteSurveyState.selectedSurveyStep.maxAnswers : ''})                             
                        </div>
                    </div>

                </div>
                }
            </div>
        </Dialog>}
        </>
    )    
}

export { SiteSurveyBox };