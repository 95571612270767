/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { LoginIcon } from '@heroicons/react/outline'
import { LoginForm } from './LoginForm'
import { RegisterForm } from './RegisterForm'
import { ForgotPasswordForm } from './ForgotPasswordForm'
import { ResetPasswordForm } from './ResetPasswordForm'
import { EmailVerificationForm } from './EmailVerificationForm'
import { accountService } from '../../_services'

function ProfileModal({activeProfileModal}) {
    const [open, setOpen] = useState(activeProfileModal > 1)        
    const [activeView, setActiveView] = useState(activeProfileModal)    

    function closeModal() {
        setOpen(false)
      }
    
      function openModal() {
        setActiveView(1)
        setOpen(true)
      }
    
    return (
        <>
            <button type="button" onClick={() => openModal()}
                className="px-3 py-1 text-sm font-medium text-white bg-black rounded-lg bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <span className="relative inline-block">
                    {accountService.userValue && 
                    <p className='text-2xl mb-2'>{(accountService.userValue.firstName[0] + accountService.userValue.lastName[0]).toUpperCase()}</p>}
                    {/* <UserIcon className="h-6 w-6 text-white-600" aria-hidden="true" /> */}
                    {accountService.userValue == null && 
                    <LoginIcon className="h-6 w-6 m-1 text-white-600" aria-hidden="true" />}                    
                </span>
            </button>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen} open={open}>
                <div className="block items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />                        
                    
                    {activeView === 1 && 
                    <LoginForm 
                        onClose={() => setOpen(false)}
                        goRegister={() => setActiveView(2)}
                        goForgotPassword={() => setActiveView(3)}>
                    </LoginForm>}                                          
                    
                    {activeView === 2 && 
                    <RegisterForm 
                        onClose={() => setOpen(false)}
                        goLogin={() => setActiveView(1)}>
                    </RegisterForm>}                                          
                    
                    {activeView === 3 && 
                    <ForgotPasswordForm 
                        onClose={() => setOpen(false)}
                        goLogin={() => setActiveView(1)}>
                    </ForgotPasswordForm>}                                          

                    {activeView === 4 && 
                    <ResetPasswordForm 
                        onClose={() => setOpen(false)}
                        goLogin={() => setActiveView(1)}>
                    </ResetPasswordForm>}                                          

                    {activeView === 5 && 
                    <EmailVerificationForm 
                        onClose={() => setOpen(false)}
                        goLogin={() => setActiveView(1)}>
                    </EmailVerificationForm>}                                          
                </div>
            </Dialog>            
        </>
    )
}

export { ProfileModal };