import React,{useEffect, useState} from 'react'; 
import { languageService } from '../_services'
import { AppContext } from '../_data/shop/state';
import { Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function LanguageSelect ({appSiteId}) 
{     
    const { i18n } = useTranslation()

    const { state, setLanguage } = React.useContext(AppContext)
    //const [code, setCode] = useState('_')
    const [languages, setLanguages] = useState([])

    //languageService.setLanguage(state.languageCode)

    useEffect(() => {
        languageService.getlanguagesOfSite(appSiteId)
        .then(_langs => {
            if (_langs.totalCount > 0) 
                setLanguages(_langs.result)            
        })
    },[appSiteId])
    
    const handleChange = (code) => {
        setLanguage(code)
        i18n.changeLanguage(code);
        languageService.setLanguage(code)
    }    
               
    return ( 
    <div> 
        <div className="language-flags flex space-x-2 mt-2 justify-end">
            <Image onClick={() => {handleChange('_')}} className={`${('_' === state.languageCode ? 'opacity-100': '')} flag w-8 opacity-60 hover:opacity-100 cursor-pointer`} src='it.png'></Image>
            {languages && languages.map(lang => 
                <Image onClick={() => {handleChange(lang.code)}} alt={lang.code} className={`${(lang.code === state.languageCode ? 'opacity-100': '')} flag w-8 opacity-60 hover:opacity-100 cursor-pointer`} key={lang.code} src={baseImageUrl+lang.imageUrl}></Image>                            
            )}
        </div>
    </div> 
    ); 
  } 
  
  export { LanguageSelect };