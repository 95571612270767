import React, { useState, useEffect} from 'react'
import { SearchIcon } from '@heroicons/react/outline';
import { appSiteService } from '../../_services'
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function SearchDialogResult({appSiteId, onChange}) 
{
    const [search, setSearch] = useState('')
    const [sitePages, setSitePages] = useState([])    
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {        
        if (appSiteId > 0 && search != null && search !== '') {
            setLoading(true)
            appSiteService.searchOnSite(appSiteId,search)
                .then((_sitePages) => {
                    setLoading(false)
                    setSitePages(_sitePages)
                });
        }
    },[appSiteId,search])
        
    return (
        <div className="inline-block align-bottom h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <SearchIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className='p-2 mt-3'>
                    <input type="text" placeholder='Search' className="form-control" value={search} onChange={(e) => setSearch(e.target.value)}  />
                </div>
            </div>
            <div className="h-full static overflow-y-auto pb-72 bg-white px-4">                                               
                

                {loading &&
                <div class="border mt-3 border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                    <div class="animate-pulse flex space-x-4">
                        <div class="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div class="flex-1 space-y-6 py-1">
                        <div class="h-2 bg-slate-700 rounded"></div>
                        <div class="space-y-3">
                            <div class="grid grid-cols-3 gap-4">
                            <div class="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div class="h-2 bg-slate-700 rounded"></div>
                        </div>
                        </div>
                    </div>
                </div>}

                <div className='mt-3 space-y-2'>                    

                    {sitePages && sitePages.length > 0 && sitePages.map(sitePage => 
                    <a href={`/${sitePage.sitePageId}`} key={sitePage.sitePageId} className="flex items-start space-x-6 p-6 hover:no-underline rounded-lg border hover:bg-gray-200">                                        
                                                
                        <div className='border p-2 rounded-lg' style={{backgroundColor: sitePage.boxColor}}>
                            {sitePage.imageUrl &&
                            <img width="60" height="88" className="flex-none rounded-md bg-slate-100" src={baseImageUrl+sitePage.imageUrl} alt={sitePage.titleUrl} />}
                        </div>
                        
                        <div className='min-w-0 relative flex-auto'>
                            <span className="font-semibold text-slate-900 truncate pr-20">{sitePage.titleUrl && parse(sitePage.titleUrl)}</span>
                            {/* <dl className="mt-2 flex flex-wrap text-sm leading-6 font-medium">
          
                                <div className="absolute top-0 right-0 flex items-center space-x-1">
                                    <dt className="text-sky-500">
                                    <span className="sr-only">Star rating</span>
                                    <svg width="16" height="20" fill="currentColor">
                                        <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
                                    </svg>
                                    </dt>
                                    <dd>{sitePage.sitePageId}</dd>
                                </div>
                                <div>
                                    <dt className="sr-only">NavColor</dt>
                                    <dd className="px-1.5 ring-1 ring-slate-200 rounded">{sitePage.navColor}</dd>
                                </div>
                                <div className="ml-2">
                                    <dt className="sr-only">Description</dt>
                                    <dd>{sitePage.boxColor}</dd>
                                </div>                            
                            </dl> */}
                        </div>                                        
                    </a>
                    )}
                </div>                

            </div>            
                       
        </div>
    );
}

export { SearchDialogResult }