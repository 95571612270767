import React, { useState} from 'react'
import { shopService, alertService, accountService } from '../../_services'
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { RegistryDetail } from './RegistryDetail'
import { AppContext } from '../../_data/shop/state'
import { useTranslation } from 'react-i18next'

function Checkout({appSiteId,cartDetails,onClose,goCartDetails,goLogin,goPayment}) 
{                   
    const [registry, setRegistry] = useState(false)
    const [message, setMessage] = useState('')
    const { state,saveOrder,saveOrderId } = React.useContext(AppContext);

    const { t } = useTranslation();

    const handleSaveOrder = () => {        

        var _cartDetails = JSON.parse(JSON.stringify(state.cartDetails)); // [...state.cartDetails];
        _cartDetails.forEach(element => {
            delete element.siteProduct;
            delete element.siteSurvey;
        })
        
        // Check orderId 
        var _order = { ...state.order, orderId: state.orderId === '' ? 0 : state.order.orderId }
        // Create or update order on db
        shopService.createOrder(_order, registry,_cartDetails)            
            .then((_order) => {
                if (!_order.hasErrors) {
                    saveOrder(_order)
                    saveOrderId(`${_order.orderYear}-${_order.orderId}`)
                    goPayment()
                    alertService.success('Order sent successfully', { keepAfterRouteChange: true });
                } else {                
                    alertService.error('There are problems with your order', { keepAfterRouteChange: true });
                }
            })
    }
            
    return (
        <div className="inline-block align-bottom h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ShoppingCartIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>                
                <h3 className="text-lg text-center font-semibold">
                    {t('shop.checkout.title')}
                </h3>
            </div>
            <div className="h-full static overflow-y-auto pb-48 bg-white px-4">                                                   

                <div className='border sticky rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm text-black font-large text-center'>
                    <p><b className='text-xl'>€ {state.cartTotal}</b><br /> ({cartDetails.length} {t('shop.checkout.items')})</p>
                </div>

                {accountService.userValue && 
                <div className='border sticky rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm text-black font-large text-center'>                    
                    <b>{accountService.userValue.email}</b>
                </div>}                
                
                {accountService.userValue == null && 
                <div className='mt-2 text-center'>                    
                    <div>
                    {t('shop.checkout.note')}
                        <button onClick={() => goLogin()}
                            className='block w-full rounded-md mt-2 border border-transparent shadow-sm px-4 py-2 bg-yellow-400 text-base font-medium text-black hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm'>
                            {t('shop.checkout.action1')}
                        </button>
                    </div>
                </div>}
                
                {accountService.userValue && 
                <div className='border sticky rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm'>
                    <RegistryDetail 
                        appSiteId={appSiteId} 
                        userEmail={accountService.userValue.email}
                        onRegistryConfirm={(_registry) => setRegistry(_registry)}>
                    </RegistryDetail>
                </div>}
                                
                <div className='mt-1 text-center text-red-500'>
                    <p>{message}</p>
                </div>                        

            </div>

            {accountService.userValue && registry && 
            <div className='absolute bottom-12 md:bottom-20 p-2 w-full bg-gray-200 space-x-2 text-center h-18'>
                <div className='w-full text-center'>
                    <button type="button"
                        onClick={() => handleSaveOrder()}
                        className="rounded-md border w-full flex items-center border-transparent shadow-sm px-4 py-2 bg-yellow-400 justify-center font-medium text-black hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            {t('shop.checkout.action2')}
                    </button>
                </div>
                {/* <div className='mt-1 px-4 py-1 text-gray-500 text-xs text-center'>
                    <p>Your order will be handled as soon as possible by our staff. You will shortly receive an email with delivery details.</p>
                </div> */}
            </div>}

            <div className="absolute bottom-0 md:bottom-8 p-2 w-full bg-gray-200  space-x-2 flex text-center h-18">
                <button type="button"
                    className="flex-1 flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 text-sm"
                    onClick={() => goCartDetails()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                        {t('shop.checkout.action3')}
                </button>
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 text-sm"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                    </svg>
                    {t('shop.checkout.action4')}
                </button>
            </div>
        </div>
    );
}

export { Checkout }