import React,{useState, useEffect} from 'react'; 
import { shopService, alertService } from '../_services'
import { AppContext } from '../_data/shop/state';
import { Image } from 'react-bootstrap'
import parse from 'html-react-parser'

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function ActionBtn({siteProduct, addToCart, landingPageId}) {

    if (siteProduct == null)
        return (<></>)
    
    return (
        <div className='flex items-center mx-auto space-x-2'>
            {siteProduct && siteProduct.stockQuantity !== 0 && siteProduct.siteProductType && siteProduct.siteProductType.category === 1 &&
            <button onClick={() => addToCart(siteProduct)} className="mx-auto h-10 px-6 font-semibold rounded-md bg-black text-white" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
            </button>}
            {landingPageId > 0 &&
            <a href={`/${landingPageId}`} className="mx-auto">                
                <div className="h-10 w-24 flex items-center px-3 font-semibold rounded-md bg-gray-200 text-black">
                view
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </div>
            </a>}
        </div>)
}

function SiteProductPreview({ appSiteId, siteProductId, template, landingPageId }) 
{
    const { addItemToCart } = React.useContext(AppContext);        
    const [siteProduct, setSiteProduct] = useState()

    useEffect(() => {
        if (siteProductId > 0) {
            shopService.getSiteProduct(appSiteId, siteProductId)
                .then((result) => {
                    setSiteProduct(result)
                })
        }
    },[appSiteId, siteProductId])

    const handleAddItemToCart = (_siteProduct) => {
        if(_siteProduct.stockQuantity === 0)
            return false;

        var cartItem = {
            productId: _siteProduct.siteProductId,
            siteProductId: _siteProduct.siteProductId,
            quantity: 1,
            price: _siteProduct.price,            
            discount: 0,
            siteProduct: _siteProduct 
        }
        // Shop State add item (only local)
        addItemToCart(cartItem)   
        // Update quantity
        if (_siteProduct.stockQuantity !== -1) {
            var updSiteProduct = {..._siteProduct, stockQuantity: _siteProduct.stockQuantity - 1 };
            shopService.updateSiteProduct(updSiteProduct)
                .then((_updSiteProduct) => { 
                    setSiteProduct(updSiteProduct)
                });
        }
        
        // Alert show
        alertService.success('Go to shopping cart to proceed with the order', { keepAfterRouteChange: true });
    }        
    
    return (
        <>
            {siteProduct && 
            <div>
                {template === 1 &&
                <div className='border rounded-xl overflow-hidden md:flex' style={{backgroundColor: siteProduct.boxColor}}>
                    <Image className='w-full h-56 md:h-64 md:w-48 object-cover' src={baseImageUrl+siteProduct.imageUrl} />                    
                    <div className='p-2'>
                        <h1 className='font-extrabold text-xl'>{siteProduct.code}</h1>
                        {siteProduct.siteProductType && siteProduct.siteProductType.category === 1 &&
                        <div className='font-bold'> € {siteProduct.price} </div>}
                        <div className='h-12 overflow-hidden'>
                            {siteProduct.description && parse(siteProduct.description)}
                        </div>
                        <div className="space-x-4 mb-6 text-sm font-medium">
                            <div className="mx-auto">
                            <ActionBtn siteProduct={siteProduct} addToCart={handleAddItemToCart} landingPageId={landingPageId} />                                                          
                            </div>                            
                        </div>
                    </div>
                </div>}

                {template === 2 &&
                <div className="flex font-sans">
                    <div className="flex-none w-48 relative">
                        <img src={baseImageUrl+siteProduct.imageUrl} alt="" className="absolute inset-0 w-full h-full object-cover" />
                    </div>
                    <form className="flex-auto p-6">
                        <div className="flex flex-wrap">
                            <h1 className="flex-auto text-lg font-extrabold text-slate-900">
                            {siteProduct.code}
                            </h1>
                            {siteProduct.siteProductType && siteProduct.siteProductType.category === 1 &&
                            <div className="text-lg font-semibold">                            
                                € {siteProduct.price}
                            </div>}                            
                        </div>
                        
                        <div className="flex space-x-4 mb-6 text-sm font-medium">
                            <div className="flex-auto flex space-x-4">
                            <ActionBtn siteProduct={siteProduct} addToCart={handleAddItemToCart} landingPageId={landingPageId} />                                               
                            </div>                            
                        </div>
                        <p className="text-sm text-slate-700">
                            {siteProduct.description && parse(siteProduct.description)}
                        </p>
                    </form>
                </div>}
                

                {template === 3 &&
                <div className='bg-cover h-56 flex items-center text-center' style={{backgroundImage: `url(${baseImageUrl+siteProduct.imageUrl})`}}>
                    <div className='flex-1 text-center'>
                        <h1 className="backdrop-blur-sm bg-white/30 w-full text-2xl font-black text-gray-800">
                        {siteProduct.code}
                        </h1>
                        {siteProduct.siteProductType && siteProduct.siteProductType.category === 1 &&
                        <div className="text-lg font-semibold">                            
                            € {siteProduct.price}
                        </div>}
                        <div className="w-full flex-none text-sm font-medium text-slate-700 mt-2">
                            {siteProduct.description && parse(siteProduct.description)}
                        </div>
                        <div className="space-x-4 mb-6 text-sm font-medium">
                            <div className="mx-auto">
                                <ActionBtn siteProduct={siteProduct} addToCart={handleAddItemToCart} landingPageId={landingPageId} />                                
                            </div>                            
                        </div>
                    </div>
                </div>}

                {template === 4 &&
                <div className='h-56 flex items-center text-center' style={{backgroundColor: siteProduct.boxColor}}>
                    <div className='flex-1 text-center'>
                        <h1 className="w-full text-xl font-extrabold">
                        {siteProduct.code}
                        </h1>
                        {siteProduct.siteProductType && siteProduct.siteProductType.category === 1 &&
                        <div className="text-lg font-semibold">                            
                            € {siteProduct.price}
                        </div>}
                        <div className="w-full flex-none text-sm font-medium text-slate-700 mt-2">
                            {siteProduct.description && parse(siteProduct.description)}
                        </div>
                        <div className="space-x-4 mb-6 text-sm font-medium">
                            <div className="mx-auto">
                            <ActionBtn siteProduct={siteProduct} addToCart={handleAddItemToCart} landingPageId={landingPageId} />                                
                            </div>                            
                        </div>
                    </div>
                </div>}

            </div>}
        </>
    );
}

export { SiteProductPreview }