import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from '../_helpers';

//import { config } from '../config.json'
// export const config = require("../config.json");
// const baseUrl = `${config.REACT_APP_API_URL}/Order`;
const baseUrl = `${process.env.REACT_APP_API_URL}/Order`;

const paymentUrl = `${process.env.REACT_APP_API_URL}/Payment`;

const cartSubject = new BehaviorSubject([]);

export const shopService = {
    getSiteProduct,
    updateSiteProduct,
    createOrder,
    getOrder: getOrder,
    getRegistryByEmail,
    createOrUpdateRegistry,
    getPaymentIntent,
    confirmPayment,
    confirmPaymentById,
    cart: cartSubject.asObservable(),
    get cartValue () { return cartSubject.value },
}

function getSiteProduct(appSiteId, siteProductId) {
    return fetchWrapper.get(`${baseUrl}/GetSiteProduct/${appSiteId}/${siteProductId}`);
}

function updateSiteProduct(siteProduct) {
    return fetchWrapper.post(`${baseUrl}/UpdateSiteProduct`, { siteProduct });
}

function createOrder(order, registry, orderDetails) {
    return fetchWrapper.post(`${baseUrl}/CreateOrUpdateOrder`, { order: order, registry: registry, orderDetails: orderDetails });
}

function getOrder(appSiteId, orderId) {
    return fetchWrapper.get(`${baseUrl}/GetSiteOrder/${appSiteId}/${orderId}`);
}

function getRegistryByEmail(email) {
    return fetchWrapper.get(`${baseUrl}/GetRegistryByEmail/${email}`);
}

function createOrUpdateRegistry(registry) {
    return fetchWrapper.post(`${baseUrl}/CreateOrUpdateRegistry`, { registry });
}

function getPaymentIntent(appSiteId,amount,currency) {
    return fetchWrapper.post(`${paymentUrl}/GetPaymentIntent`, { appSiteId, amount, currency });
}

function confirmPayment(paymentId) {
    return fetchWrapper.post(`${paymentUrl}/ConfirmPayment`, { paymentId });
}

function confirmPaymentById(appSiteId, orderId, paymentId) {
    return fetchWrapper.post(`${paymentUrl}/ConfirmPaymentById`, { appSiteId, orderId, paymentId });
}