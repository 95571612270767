import React from 'react';
import { Container,Nav } from 'react-bootstrap';
import { appSiteService, alertService, shopService } from '../../_services';
import parse from 'html-react-parser';
import { useState } from 'react';
import { useEffect } from 'react';
import { AppContext } from '../../_data/shop/state';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function ProductList ({appSiteId, sitePageId,pageBoxId,productTypeId}) 
{
    const { addItemToCart } = React.useContext(AppContext);
    const [productTypes, setProductTypes] = useState([])
    const [products, setProducts] = useState()    
    
    useEffect(() => {
        getProducts(appSiteId, sitePageId, pageBoxId, productTypeId)
    },[appSiteId, sitePageId, pageBoxId, productTypeId])
    
    useEffect(() => {
        appSiteService.getProductTypes(appSiteId, sitePageId, pageBoxId)
            .then((_productTypes) => setProductTypes(_productTypes.result));
    },[appSiteId,sitePageId,pageBoxId])
    
    const getProducts = (appSiteId, sitePageId, pageBoxId, productTypeId) => {     
        appSiteService.getProductsOfBox(appSiteId, sitePageId, pageBoxId, productTypeId)
            .then((_products) => {
                setProducts((_products.totalCount > 0 ? _products.result : []));
            })
            .catch(() => {});        
    }

    const handleAddItemToCart = (_siteProduct) => {
        if(_siteProduct.stockQuantity === 0)
            return false;

        var cartItem = {
            productId: _siteProduct.siteProductId,
            siteProductId: _siteProduct.siteProductId,
            quantity: 1,
            price: _siteProduct.price,            
            discount: 0,
            siteProduct: _siteProduct 
        }
        // Shop State add item (only local)
        addItemToCart(cartItem)       
        // Update quantity
        if (_siteProduct.stockQuantity !== -1) {
            var updSiteProduct = {..._siteProduct, stockQuantity: _siteProduct.stockQuantity - 1 };
            shopService.updateSiteProduct(updSiteProduct)
                .then((_updSiteProduct) => getProducts(appSiteId,sitePageId,pageBoxId,productTypeId)); 
        }
        // Alert show
        alertService.success('Go to shopping cart to proceed with the order', { keepAfterRouteChange: true });
    }    
    
    return (
        <div className="description">
            <Container fluid>              
                {/* <Nav fill variant="tabs">
                    <Nav.Item><Nav.Link onClick={() => getProducts(appSiteId,sitePageId,pageBoxId,0)}>TUTTI</Nav.Link></Nav.Item>
                    {productTypes && productTypes.map(productType => 
                    <Nav.Item key={productType.productTypeId}>
                        <Nav.Link onClick={() => getProducts(appSiteId,sitePageId,pageBoxId,productType.productTypeId)}>{productType.name}</Nav.Link>
                    </Nav.Item>
                    )}                                                
                </Nav>   */}
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 space-x-2'>

                    {products && products.map(product =>                                    
                        <div key={product.productId} className="rounded-xl shadow-md overflow-hidden text-left">
                            {product.siteProduct && 
                            <div style={{backgroundColor: product.siteProduct.boxColor}}>
                                <div className="md:shrink-0">
                                    <img className="h-48 w-full object-cover" src={baseImageUrl+product.siteProduct.imageUrl} alt={product.siteProduct.code} />
                                </div>
                                <div className="p-8 h-48 overflow-hidden">
                                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                                        € {product.siteProduct.price}
                                    </div>
                                    <span className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">
                                        {product.siteProduct.code}
                                    </span>
                                    <div className="mt-2 text-slate-500">
                                        {product.siteProduct.description && parse(product.siteProduct.description)}
                                    </div>
                                </div>
                                <div className='pt-2 pb-2'>
                                    <button onClick={() => handleAddItemToCart(product.siteProduct)} className="w-full">
                                        <span className="flex pl-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                            </svg>
                                            <span className='flex-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                                </svg>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>}                                
                        </div>                                    
                    )}

                </div>
                {!products &&                
                    <div className="text-center">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }                
            </Container>
        </div>            
    );    

}

export { ProductList };