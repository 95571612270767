import React from 'react'; 
import parse from 'html-react-parser';

function SiteSurveyBoxNav ({surveySteps, surveyStepId}) {                
    return ( 
        <nav>
            <ul name="sitesurveynav" className="flex flex-col md:flex-row">
                {surveySteps && surveySteps.length > 0 &&  surveySteps.map(surveyStep =>
                <li key={surveyStep.surveyStepId}
                    className={`md:flex-grow ${surveyStepId === surveyStep.surveyStepId ? '' : 'hidden'}`}>
                    {surveyStep.description && parse(surveyStep.description)}
                </li>              
                )}
            </ul>
        </nav>
    );      
} 

export { SiteSurveyBoxNav };