
import React, {useEffect, useState} from 'react';
//import { CartContext } from '../../_data/shop/reducer';
import { Dialog } from '@headlessui/react'
import { AppContext } from '../../_data/shop/state';
import { shopService } from '../../_services/shop.service';
import { ShopCartDetails } from './ShopCartDetails';
import { Checkout } from './Checkout';
import { LoginForm } from '../Account/LoginForm';
import { RegisterForm } from '../Account/RegisterForm';
import { ForgotPasswordForm } from '../Account/ForgotPasswordForm';

import { CheckoutForm } from './CheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { CheckoutSuccess } from './CheckoutSuccess';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function ShopCart({appSiteId,activeShopModal,stripePublicKey,optionalPay,initialCart,}) 
{  
  //const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
  const stripePromise = loadStripe(`${stripePublicKey}`);
  
  const [open, setOpen] = useState(activeShopModal > 1)        
  const { state, saveOrder } = React.useContext(AppContext)
  const [activeView, setActiveView] = useState(activeShopModal)
  
  const [stripeOptions, setStripeOptions] = useState()
  const [loadingIntent, setLoadingIntent] = useState(false)  
        
  // Description PaymentIntent
  // https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret
  useEffect(() => {
    if (state.cartTotal > 0) {
      setLoadingIntent(true)
      shopService.getPaymentIntent(appSiteId,state.cartTotal,'eur')
        .then((paymentIntent) => {
          if (paymentIntent)
            setStripeOptions({ clientSecret: paymentIntent.clientKey })
            setLoadingIntent(false)
            saveOrder({
              ...state.order,
              stripeClientKey: paymentIntent.clientKey,
              stripePaymentId: paymentIntent.paymentId
            })
        })
    }

  },[state.cartTotal])

    const openModal = () => {        
        setActiveView(1)
        setOpen(true)
    }    
    
    return (
      <>
        <button onClick={() => openModal()} className='px-4 py-2 text-sm font-medium text-white bg-black rounded-lg bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
          <span className="relative inline-block">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full">{state.cartDetails && state.cartDetails.length}</span>
          </span>
          
        </button>
        <Dialog as="div" className="fixed z-10 inset-0" onClose={setOpen} open={open}>
          <div className="static block items-end justify-center min-h-screen pt-4 pb-4 text-center sm:p-0">
            <Dialog.Overlay onClick={() => setOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />                        
            <div className="absolute left-2 right-2 top-7 bottom-7 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">

              <div className="block relative mx-auto w-full md:w-1/2 h-full rounded-xl flex-grow overflow-hidden">

                <div className='absolute md:mt-8 top-0 right-0 z-10 text-gray-400 p-2 cursor-pointer' onClick={() => setOpen(false)}>                                            
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>

                {activeView === 1 && state.cartDetails &&   
                <ShopCartDetails 
                  cartDetails={state.cartDetails}
                  onClose={() => setOpen(false)}
                  goCheckout={() => setActiveView(2)}>
                </ShopCartDetails>}
                
                {activeView === 2 && 
                <Checkout
                    appSiteId={appSiteId}
                    cartDetails={state.cartDetails}
                    onClose={() => setOpen(false)}
                    goCartDetails={() => setActiveView(1)}
                    goPayment={() => setActiveView(6)}
                    goLogin={() => setActiveView(3)}>                
                </Checkout>
                }

                {activeView === 3 && 
                <LoginForm 
                    onClose={() => setOpen(false)}
                    goRegister={() => setActiveView(4)}
                    goForgotPassword={() => setActiveView(5)}>
                </LoginForm>}                                          

                {activeView === 4 && 
                <RegisterForm 
                    onClose={() => setOpen(false)}
                    goLogin={() => setActiveView(3)}>
                </RegisterForm>}                                          
                
                {activeView === 5 && 
                <ForgotPasswordForm 
                    onClose={() => setOpen(false)}
                    goLogin={() => setActiveView(3)}>
                </ForgotPasswordForm>}        

                {!loadingIntent && stripeOptions && activeView === 6 &&               
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <CheckoutForm 
                    orderId={state.orderId} 
                    cartTotal={state.cartTotal} 
                    cartDetails={state.cartDetails}
                    optionalPay={optionalPay}
                    onClose={() => setOpen(false)}
                    goCheckout={() => setActiveView(2)} />
                </Elements>}                              

                {activeView === 7 && 
                <CheckoutSuccess 
                    appSiteId={appSiteId}
                    onClose={() => setOpen(false)}>
                </CheckoutSuccess>}        

              </div>
            </div>

          </div>
        </Dialog>                    
      </>
    );
  }

export { ShopCart }
  