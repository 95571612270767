import React, { useEffect, useState } from 'react'
import { accountService } from '../../_services/account.service'
import { Dialog } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';

function EmailVerificationForm({onClose,goLogin}) 
{    
    var tokenIndex = window.location.search.indexOf('token=');
    const token = window.location.search.substring(tokenIndex + 6);

    const [message, setMessage] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        accountService.verifyEmail(token)
        .then(() => {
            setMessage('')
            goLogin()
        })
        .catch(error => {
            setMessage('Richiesta non riuscita :-(')
        });
    },[token])

    return (
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <Dialog.Title as="h3" className="text-lg text-center font-semibold">
                    {t('account.verificationForm.title')}
                </Dialog.Title>
            </div>

            {(token == null || token === '') && 
            <div className='mt-2 text-center'>
                <p className='text-red-500'>
                    {t('account.verificationForm.subTitle')}
                </p>
            </div>}
            
            {message && 
            <div className='mt-2 text-center text-red-500 bg-red-100 rounded-lg border mx-4 pt-2'>
                <p>{message}</p>
            </div>}
            <div className='mt-1 px-4 py-1 text-gray-500 text-xs text-center'>
                <p>{t('account.verificationForm.note')}</p>
            </div>                    
            <div className="flex bg-gray-200 px-4 py-3 sm:px-6 flex-col sm:flex-row-reverse space-y-1">
                <button type="button"
                    className="flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 sm:text-sm"
                    onClick={() => goLogin()}>
                    {t('account.verificationForm.action1')}
                </button>             
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 sm:text-sm">
                    {t('account.verificationForm.action2')}
                </button>
            </div>
        </div>
    );
}

export { EmailVerificationForm }