import React from 'react';
import * as moment from 'moment'
import { Container, Card, Row, Col } from 'react-bootstrap';
import { appSiteService } from '../../_services';

const weekDays = [
    { value: 1, label: 'Lunedì'},
    { value: 2, label: 'Martedì' },
    { value: 3, label: 'Mercoledì' },
    { value: 4, label: 'Giovedì' },
    { value: 5, label: 'Venerdì' },
    { value: 6, label: 'Sabato' },
    { value: 7, label: 'Domenica' }
]

class OpenTimeList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            openTimes: null,
            appSiteId: this.props.appSiteId,
            sitePageId: this.props.sitePageId,
            pageBoxId: this.props.pageBoxId
        }        
    }

    componentDidMount() {
        this.getOpenTimes(this.props.appSiteId, this.props.sitePageId, this.props.pageBoxId);
    }

    getOpenTimes(appSiteId, sitePageId, pageBoxId) {            
        appSiteService.getOpenTimesOfBox(appSiteId, sitePageId, pageBoxId)
            .then((_openTimes) => {
                this.setState({ openTimes: (_openTimes.totalCount > 0 ? _openTimes.result : []) });                                
            })
            .catch(() => {});        
    }
    
    render() {
        return (
            <div className="description">
                <Container>
                    <Row className="mart2" >
                    {this.state.openTimes && this.state.openTimes.map(openTime =>                
                        <Col sm={4} key={openTime.weekDay}>
                            <Card>                            
                                <Card.Header>
                                    <h1>{weekDays[openTime.weekDay - 1].label}</h1>
                                </Card.Header>
                                <Card.Body>                                                                
                                    <Card.Text>
                                    <b>Pranzo</b>: {moment(openTime.startTime).format('HH:mm')} - {moment(openTime.pauseTime).format('HH:mm')}<br />
                                    <b>Cena</b>: {moment(openTime.resumeTime).format('HH:mm')} - {moment(openTime.endTime).format('HH:mm')}
                                    </Card.Text>                                
                                </Card.Body>
                            </Card>                        
                        </Col>                                
                    )}                    
                    {!this.state.openTimes &&                
                        <Col className="text-center">
                            <span className="spinner-border spinner-border-lg align-center"></span>
                        </Col>
                    }
                    </Row>
                </Container>
            </div>            
        );
    }

}

export { OpenTimeList };