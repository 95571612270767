import React from 'react';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { contactService } from '../../_services/contact.service';

class BoxContact extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            appSiteId: props.appSiteId,
            sitePageId: props.sitePageId,
            pageBoxId: props.pageBoxId,
            title: props.title || 'Contattaci',
            email: '',
            subject: '',
            message: '',
            result: '',
            sended: false
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            [evt.target.name]: value          
        });
    }    

    onSubmit = () => {
        contactService.sendForm(this.state.appSiteId,this.state.sitePageId,this.state.pageBoxId, this.state.email,this.state.subject,this.state.message)
            .then(() => { 
                this.setState({ result: 'Richiesta inviata', sended: true })
            });
    }

    render() {
        return (
            <div className="description">
                <Container>
                    <Card className='shadow-lg'>
                        <Card.Header>
                            <Card.Title>{this.state.title}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Indirizzo email</Form.Label>
                                        <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.handleChange} maxLength={200} />
                                        <Form.Text className="text-muted">
                                            Inserire un indirizzo email valido. 
                                        </Form.Text>
                                    </Form.Group>                     
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Motivo</Form.Label>
                                        <input type="text" className="form-control" name="subject" value={this.state.subject} onChange={this.handleChange} maxLength={200} />
                                        <Form.Text className="text-muted">
                                            Motivo della richiesta. 
                                        </Form.Text>
                                    </Form.Group>                     
                                </Col>
                            </Row>                                                        

                            <Form.Group>
                                <Form.Label>Richiesta</Form.Label>
                                <textarea className="form-control" name="message" value={this.state.message} onChange={this.handleChange}></textarea>                                
                            </Form.Group>                     
                        </Card.Body>
                        <Card.Footer>
                            {!this.state.sended &&
                            <Button onClick={this.onSubmit} variant="success" className="mr-1">
                                Invia
                            </Button> 
                            }
                            <h1>{this.state.result}</h1>                            
                        </Card.Footer>
                    </Card>
                </Container>
            </div>
        );    
    }
}

export { BoxContact };