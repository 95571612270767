import React, { useState} from 'react'
import { ShoppingCartIcon, TrashIcon } from '@heroicons/react/outline'
import { shopService } from '../../_services'
import { AppContext } from '../../_data/shop/state'
import { useTranslation } from 'react-i18next'

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function ShopCartDetails({cartDetails,onClose,goCheckout}) 
{
    const { state, resetCart, removeItemFromCart } = React.useContext(AppContext);
    const [message, setMessage] = useState('');
    // const [cartTotal, setCartTotal] = useState(0);

    const { t } = useTranslation();

    const removeProduct = (_product) => {
        // Update quantity
        shopService.updateSiteProduct({..._product.siteProduct, stockQuantity: _product.siteProduct.stockQuantity + _product.quantity })
            .then(removeItemFromCart(_product.productId));        
    }

    return (
        <div className="inline-block align-bottom h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ShoppingCartIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <h3 className="text-lg text-center font-semibold">
                {t('shop.cartDetails.title')}
                </h3>
            </div>
            <div className="h-full static overflow-y-auto pb-72 bg-white px-4">            
                
                <div className='border sticky rounded-lg mt-2 px-4 py-2 bg-gray-50 shadow-sm text-black font-large text-center'>
                    <p><b className='text-xl'>€ {state.cartTotal}</b><br /> ({cartDetails.length} {t('shop.cartDetails.items')})</p>
                </div>                       
                
                <div className='mt-3'>                    
                    {cartDetails && cartDetails.length > 0 && cartDetails.map(product => 
                    <div className='flex' key={product.productId}>                
                        <>
                            {product.siteProduct && 
                            <div className='border flex rounded-lg overflow-hidden w-full'>
                                <div className="w-20">
                                    <img className="h-20 object-cover" src={baseImageUrl+product.siteProduct.imageUrl} alt={product.siteProduct.code} />
                                </div>
                                <div className="flex-grow p-3 leading-tight text-black">
                                    <div className='text-lg leading-tight font-medium'>{product.siteProduct.code}</div>
                                    <div className='leading-tight text-sm'>
                                    {t('shop.cartDetails.qty')} {product.quantity}
                                    </div>
                                </div>                                
                                <div className="p-4">
                                    <div className="uppercase tracking-wide font-medium text-indigo-500">
                                        € {product.price}
                                    </div>
                                </div>                        
                                <button className='bg-red-400 text-red-900 hover:bg-red-300 rouded-full pl-2 pr-2' onClick={() => removeProduct(product)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>}                        
                        </>
                        <>
                            {product.siteSurvey &&
                            <div className='border flex rounded-lg overflow-hidden w-full'>
                                <div className="w-20">
                                    <img className="h-20 object-cover" src={baseImageUrl+product.siteSurvey.imageUrl} alt={product.siteSurvey.siteSurveyName} />
                                </div>
                                <div className="flex-grow p-3 leading-tight text-black">
                                    <div className='text-lg leading-tight font-medium'>{product.siteSurvey.siteSurveyName}</div>
                                    <div className='leading-tight text-sm'>
                                    {t('shop.cartDetails.qty')} {product.quantity}
                                    </div>
                                </div>                                
                                <div className="p-4">
                                    <div className="uppercase tracking-wide font-medium text-indigo-500">
                                        € {product.price}
                                    </div>
                                </div>                        
                                <button className='bg-red-400 text-red-900 hover:bg-red-300 rouded-full pl-2 pr-2' onClick={() => removeItemFromCart(product.productId)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>}
                        </>
                    </div>
                    )}
                </div>                

            </div>

            <div className='mt-1 text-center text-red-500'>
                <p>{message}</p>
            </div>      

            {state.cartTotal > 0 &&
            <div className='absolute bottom-14 md:bottom-20 p-2 w-full bg-gray-200 space-x-2 flex text-center h-18'>
                <button type="button"
                    className="flex-1 flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-400 font-medium text-black hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-lg"
                    onClick={() => goCheckout()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        {t('shop.cartDetails.action1')}
                </button>
            </div>}
            
            <div className="absolute bottom-0 md:bottom-8 p-2 w-full bg-gray-200  space-x-2 flex text-center h-18">
                <button type="button"
                    onClick={() => resetCart([])}
                    className="flex-1 flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 text-sm">
                    <TrashIcon className="h-4 w-4 m-1 text-white-600" aria-hidden="true" />
                    {t('shop.cartDetails.action2')}
                </button>
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 text-sm"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                    </svg>
                    {t('shop.cartDetails.action3')}
                </button>
            </div>
        </div>
    );
}

export { ShopCartDetails }