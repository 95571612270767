import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { appSiteService } from '../../_services'
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

class TopServiceList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            topServices: null,
            appSiteId: this.props.appSiteId,
            sitePageId: this.props.sitePageId,
            pageBoxId: this.props.pageBoxId
        }        
    }

    componentDidMount() {
        this.getTopServices(this.props.appSiteId, this.props.sitePageId, this.props.pageBoxId);
    }

    getTopServices(appSiteId, sitePageId, pageBoxId) {            
        appSiteService.getTopServicesOfBox(appSiteId, sitePageId, pageBoxId)
            .then((_topServices) => {
                this.setState({ topServices: (_topServices.totalCount > 0 ? _topServices.result : []) });                
            })
            .catch(() => {});        
    }
        
    render() {
        return (
            <Container className="top-service">
                <Row className="mart2" >
                {this.state.topServices && this.state.topServices.map(topService =>                
                    <Col sm={parseInt(topService.cardSize)} key={topService.topServiceId}>
                        <div>
                            <Card className="mart2">
                                <Card.Img variant="top" src={baseImageUrl+topService.imageUrl} />
                                {topService.title && topService.title !== '' && 
                                <Card.Body>                                
                                    <Card.Title>
                                        {topService.title}
                                    </Card.Title>                                    
                                    {topService.description && parse(topService.description)}                                    
                                </Card.Body>
                                }
                            </Card>                        
                        </div>                        
                    </Col>                                
                )}                    
                {!this.state.topServices &&                
                    <Col className="text-center">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </Col>
                }
                </Row>
            </Container>
        );
    }

}

export { TopServiceList };