import React, { useEffect, useState } from 'react';
import { surveyService } from '../../_services';
import { Form } from 'react-bootstrap';
import { SiteSurveyBoxAnswerButton } from './SiteSurveyBoxAnswerButton';
import { SiteSurveyBoxAnswerCheck } from './SiteSurveyBoxAnswerCheck';

function SiteSurveyBoxQuestion ({appSiteId, siteSurveyId, surveyStepId, stepQuestionId, stepQuestion, surveyStep, countQty, addUserAnswer, userAnswers, handleValid, handleCount }) {
    
    const [questionAnswers, setQuestionAnswers] = useState([])
    const [questionComment, setQuestionComment] = useState('')
    const [answerComment, setAnswerComment] = useState('')
    const [countUserAnswers, setCountUserAnswers] = useState(0)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        surveyService.getAnswersOfQuestion(appSiteId,siteSurveyId,surveyStepId,stepQuestionId)
            .then((x) => setQuestionAnswers(x.result))        
    },[appSiteId, siteSurveyId, surveyStepId, stepQuestionId])

    useEffect(() => {
        var isValid = false
        if (stepQuestion && stepQuestion.isOptional) 
            isValid = true

        if (userAnswers == null || questionAnswers == null || stepQuestion == null)
            return false;

        // Filter answers of current question
        var activeAnswers = userAnswers.filter(_userAnswer => {
            return (_userAnswer.siteSurveyId === siteSurveyId &&
                _userAnswer.surveyStepId === surveyStepId &&
                _userAnswer.stepQuestionId === stepQuestionId)
        });

        // Check valid min and max answers         
        if ((stepQuestion.minAnswers <= activeAnswers.length || stepQuestion.minAnswers === 0) &&
            (stepQuestion.maxAnswers >= activeAnswers.length || stepQuestion.maxAnswers === 0)) 
            isValid = true                
                    
        stepQuestion.isValid = true
        setIsValid(isValid)
        handleValid(isValid)
        setCountUserAnswers(activeAnswers.length)
        handleCount(activeAnswers.length)

    }, [stepQuestion, userAnswers])
        
    const handleAddUserAnswer = (questionAnswer, _action, _qty, _qtyAct) => {           
        if (_qtyAct && (countUserAnswers + 1) > stepQuestion.maxAnswers)
            return false;

        addUserAnswer({
            appSiteId: questionAnswer.appSiteId,
            siteSurveyId: questionAnswer.siteSurveyId,
            userSurveyId: 0,
            surveyStepId: questionAnswer.surveyStepId,
            stepQuestionId: questionAnswer.stepQuestionId,
            questionAnswerId: questionAnswer.questionAnswerId,
            userValue: questionComment,
            userComment: answerComment,
            price: (questionAnswer.siteProduct !== undefined ? questionAnswer.siteProduct.price : questionAnswer.price),
            quantity: +_qty            
        }, stepQuestion, _action, questionAnswer.nextStepId, _qtyAct)
    }
    
    // Verifica se la risposta è selezionata
    const checkIsActive = (questionAnswer) => {
        if (userAnswers == null)
            return false;        

        var isActive = false;
        userAnswers.forEach(_userAnswer => {
            if (_userAnswer.surveyStepId === questionAnswer.surveyStepId &&
                _userAnswer.stepQuestionId === questionAnswer.stepQuestionId &&
                _userAnswer.questionAnswerId === questionAnswer.questionAnswerId) {
                isActive = true;
            }
        });

        return isActive;
    } 
    
    return (
        <div className={`${(isValid ? '' : '')}`}>
            <ul className="flex flex-col md:flex-row m-1 md:flex-wrap">
                {questionAnswers && questionAnswers.length > 0 && questionAnswers.map(questionAnswer =>
                <li key={questionAnswer.questionAnswerId} className=" w-full pr-2 m-1">
                    
                    {stepQuestion && stepQuestion.questionStyle === 2 &&
                    <SiteSurveyBoxAnswerButton 
                        initialValue={checkIsActive(questionAnswer)}
                        questionAnswer={questionAnswer} 
                        stepQuestion={stepQuestion}
                        surveyStep={surveyStep}
                        countQty={countQty}
                        userAnswers={userAnswers}
                        changeActive={(_action,_qty, _qtyAct) => handleAddUserAnswer(questionAnswer, _action,_qty, _qtyAct)} />}

                    {stepQuestion && stepQuestion.questionStyle === 1 &&
                    <SiteSurveyBoxAnswerCheck 
                        initialValue={checkIsActive(questionAnswer)}
                        questionAnswer={questionAnswer} 
                        stepQuestion={stepQuestion}
                        surveyStep={surveyStep}
                        countQty={countQty}
                        userAnswers={userAnswers}
                        changeActive={(_action,_qty, _qtyAct) => handleAddUserAnswer(questionAnswer, _action, _qty, _qtyAct)} />}

                    {questionAnswer.withComment &&
                    <Form.Group className="mt-1">                    
                        <input
                            onChange={(e) => setAnswerComment(e.target.value)}
                            type="text" 
                            placeholder="Inserisci la tua risposta"
                            className="form-control focus:ring-2 focus:ring-blue-600" />                        
                    </Form.Group>}
                </li>)}
            </ul>
        </div>
    )
}

export { SiteSurveyBoxQuestion };