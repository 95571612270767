import React, { useState, useContext, useEffect } from 'react'
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { shopService } from '../../_services'
import { AppContext } from '../../_data/shop/state'
import { useTranslation } from 'react-i18next'

function CheckoutSuccess({appSiteId, onClose}) 
{   
    var orderIdIndexStart = window.location.search.indexOf('orderId=');
    var orderIdIndexEnd = window.location.search.indexOf('&payment_intent=');
    const orderId = window.location.search.substring(orderIdIndexStart + 8, orderIdIndexEnd);
    
    var paymentIdIndexStart = window.location.search.indexOf('payment_intent=');
    var paymentIdIndexEnd = window.location.search.indexOf('&payment_intent_client_secret=');
    const paymentId = window.location.search.substring(paymentIdIndexStart + 15, paymentIdIndexEnd);

    const { t } = useTranslation();

    const { state, closeCart } = useContext(AppContext);
    const [order, setOrder] = useState()    
    
    useEffect(() => {
        if (paymentId != null && paymentId !== '' &&
            orderId != null && orderId !== '' && state.order.orderId > 0){                
                closeCart()
                shopService.confirmPaymentById(appSiteId, orderId, paymentId)
                    .then((_order) => {
                        if (!_order.hasErrors) {
                            setOrder(_order)
                        }
                    })
            }
    },[paymentId])

    // MODIFICARE CON MESSAGGIO
    if (paymentId == null) 
        return <></>;

    
    return (
        <div className="inline-block align-bottom h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ShoppingCartIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>                
                <h3 className="text-lg text-center font-semibold">
                {t('shop.checkoutSuccess.title')}
                </h3>
            </div>
            <div className='h-full static overflow-y-auto pb-48 bg-white px-4'>

                <div className="bg-white px-4 pb-2 sm:p-6 sm:pb-4 text-center">                                                   
                    <div>
                    {t('shop.checkoutSuccess.number')}<br /> 
                        <b>{orderId}</b>
                    </div>
                    {paymentId &&
                    <div className='mt-2'>
                        {t('shop.checkoutSuccess.payment')}<br /> 
                        <b>{paymentId}</b>
                    </div>}
                </div>
                
                {order && order.appSite && order.appSite.whatsappEnabled &&
                <div className='bg-green-200 rounded-md p-2 pt-3 overflow-hidden'>                    
                    <p className='text-center font-bold'>{t('shop.checkoutSuccess.note')} </p>
                </div>}
                                    
                <div className='mt-2 mb-2 px-4 py-1 text-gray-500 text-xs text-center'>                    
                    {order && 
                    <div className='flex'>
                        {order && order.appSite && order.appSite.whatsappEnabled &&
                        <a href={`https://api.whatsapp.com/send?phone=${order.appSite.phone}&text=%0d%20${order.appSite.name}%20-%20${order.orderNote}`}                            
                            className='bg-green-500 text-white hover:no-underline mx-auto p-2 rounded-lg text-lg w-48'>
                            {t('shop.checkoutSuccess.whatsapp')}
                        </a>}
                        {order && order.appSite && order.appSite.phoneEnabled &&
                        <a href={`tel:${order.appSite.phone}`}
                            className=' bg-yellow-900 text-white hover:no-underline mx-auto p-2 rounded-lg text-lg w-48'>
                            {t('shop.checkoutSuccess.call')}
                        </a>}
                    </div>}
                </div>  

                <div className='mt-1 px-4 py-1 text-gray-500 text-xs text-center'>
                    <p>{t('shop.checkoutSuccess.note2')}</p>
                </div>
            </div>

                                  
            <div className="absolute bottom-0 md:bottom-8 p-2 w-full bg-gray-200  space-x-2 flex text-center h-18">
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 text-sm"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                    </svg>
                    {t('shop.checkoutSuccess.action1')}
                </button>
            </div>
        </div>
    );
}

export { CheckoutSuccess }