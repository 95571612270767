import React from 'react';
import { Footer, Slide, NavMulti } from '../_components';
import { PageBoxList } from './PageBoxes'
import { appSiteService, languageService} from '../_services';
import { CookieBanner } from '@palmabit/react-cookie-law';
import './Home.css';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;
const appSiteId = parseInt(process.env.REACT_APP_SITE_ID);

class Home extends React.Component 
{    
    constructor({ match }) {
        super();        
        var _profileModal = 1, _shopModal = 1;
        const _urlPageId = parseInt(match.params.pageId);
        if (_urlPageId < 0) {
            if (_urlPageId === -1) _profileModal = 4;   // Password reset with token from email link
            if (_urlPageId === -2) _profileModal = 3;   // Forgot password from email link 
            if (_urlPageId === -3) _profileModal = 5;   // Verification email from email link
            if (_urlPageId === -4) _shopModal = 7;      // Payment success
            //_profileModal = parseInt(match.params.pageId) === -1 ? 4 : 1;
        }
        const _pageId = parseInt(match.params.pageId) > 0 ? parseInt(match.params.pageId) : 1 || 1;                
        
        this.state = { 
            appSite: null,
            sitePage: {
                appSiteId: appSiteId,
                sitePageId: _pageId,
                imageUrl: ''
            },
            activeProfileModal: _profileModal,
            activeShopModal: _shopModal,
            navColor: '',
            footerColor: '',
            pageBoxes: null,
            loading: true,
            loadingProgress: 0
        };        
    }

    componentDidMount() {        
        languageService.lang.subscribe(_lang => this.getAppSite())
    }

    getAppSite = () => {
        appSiteService
            .getAppSiteById(appSiteId)
            .then((_appSite) => {                                 
                this.setState({ appSite: _appSite, loadingProgress: 30 })
                this.getPage(this.state.sitePage.sitePageId);
            });
    }    

    getPage = (pageId) => {
        this.setState({ loading: true, loadingProgress: 40, pageBoxes: [] })
        appSiteService
            .getPageById(appSiteId, pageId)
            .then(_sitePage => { 
                this.setState({ 
                    sitePage: _sitePage,
                    navColor: _sitePage.navColor,
                    footerColor: _sitePage.footerColor,
                    navPosition: _sitePage.navPosition,
                    navAlign: _sitePage.navAlign,
                    loadingProgress: 70                    
                });         
                
                //history.push(`/${pageId}`)
                document.title = _sitePage.titleUrl + ' - ' + this.state.appSite.name;
                
                this.getPageBoxes(_sitePage.sitePageId);
            });        
    }

    getPageBoxes = (pageId) => {
        appSiteService
            .getBoxesOfPage(appSiteId, pageId)
            .then(x => { 
                window.scrollTo(0, 0);
                this.setState({ 
                    pageBoxes: x.result || [],
                    loading: false,
                    loadingProgress: 100 
                });                
            });
    }

    render() {
        return (
            <div className='home relative'>
                
                {!this.state.loading && this.state.appSite &&
                <NavMulti 
                    appSite={this.state.appSite}
                    activeProfileModal={this.state.activeProfileModal}
                    activeShopModal={this.state.activeShopModal}
                    navPosition={this.state.navPosition} 
                    navAlign={this.state.navAlign}
                    navColor={this.state.navColor} 
                    handleChangePage={(pageId) => this.getPage(pageId)}
                    stripePublicKey={this.state.appSite.stripePublicKey} />}

                {this.state.loading &&               
                <div className='absolute bg-gray-100 backdrop-opacity-10 top-0 bottom-0 left-0 right-0 flex items-center justify-center'>                             
                    <div className='w-3/4 md:w-1/2 mx-auto mt-2 mb-5'>                        
                        <div className='w-full text-center mb-2'>Waiting...</div>
                        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 overflow-hidden">
                            <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width: `${this.state.loadingProgress}%`}}> {this.state.loadingProgress}%</div>
                        </div>
                    </div>
                </div>}       
                    
                {this.state.appSite &&
                <div className="bg-fixed bg-contain md:bg-cover bg-no-repeat" 
                    style={{backgroundImage: `url(${baseImageUrl+this.state.sitePage.imageUrl})`, backgroundColor: this.state.sitePage.boxColor}}>                    
                    
                    <div className='body-content relative'>
                        <Slide sitePage={this.state.sitePage} appSite={this.state.appSite} />

                        {this.state.sitePage && this.state.pageBoxes &&
                        <PageBoxList 
                            appSiteId={appSiteId}
                            sitePageId={this.state.sitePage.sitePageId}
                            pageBoxes={this.state.pageBoxes} 
                            changePage={(pageId) => this.getPage(pageId)} />}
                            
                    </div>

                    {this.state.sitePage && !this.state.loading &&
                    <Footer 
                        appSiteId={appSiteId} 
                        appSite={this.state.appSite}
                        navColor={this.state.navColor} 
                        footerColor={this.state.footerColor} 
                        activeProfileModal={this.state.activeProfileModal}
                        activeShopModal={this.state.activeShopModal}
                        stripePublicKey={this.state.appSite.stripePublicKey} />}
                    
                    <CookieBanner
                        message="This site or third-party tools used by this make use of cookies necessary for the operation and useful for the purposes outlined in the cookie policy. To learn more or opt out, see the cookie policy. By accepting, you consent to the use of cookies."
                        styles={{
                            dialog: { backgroundColor: 'transparent' }
                        }}
                        policyLink={'/'+this.state.appSite.privacyPageId}
                        wholeDomain={true}
                        onAccept = {() => {}}
                        onAcceptPreferences = {() => {}}
                        onAcceptStatistics = {() => {}}
                        onAcceptMarketing = {() => {}}
                    />            
                </div>}                                        
            </div>            
        );
    }
    
}

export { Home };