import React, { useState } from 'react'
import { alertService } from '../../_services/alert.service'
import { accountService } from '../../_services/account.service'
import { Dialog } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';

const appSiteId = parseInt(process.env.REACT_APP_SITE_ID);

function ForgotPasswordForm({onClose,goLogin}) {
    const [email, setEmail] = useState('')

    const [message, setMessage] = useState('');

    const { t } = useTranslation();

    const onSubmit = () => {
        accountService.forgotPassword(email, appSiteId)
            .then(() => {
                alertService.success('Check your inbox for the password recovery procedure.', { keepAfterRouteChange: true });
                setMessage('')
                goLogin()
            })
            .catch(error => {
                alertService.error('Request failed. Contact our support.', { keepAfterRouteChange: true });
                setMessage('Richiesta non riuscita :-(')
            });
    }

    return (
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className='pt-4 pb-2 bg-gray-200'>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <Dialog.Title as="h3" className="text-lg text-center font-semibold">
                    {t('account.forgotPasswordForm.title')}
                </Dialog.Title>
            </div>
            <div className="bg-white px-4 pb-2 sm:p-6 sm:pb-4">                   
                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <form onSubmit={() => onSubmit()}>
                        <p className="text-sm text-gray-500">
                            {t('account.forgotPasswordForm.subTitle')}
                        </p>

                        <div className="form-group">
                            <input name="email" placeholder='Email' onChange={(e) => setEmail(e.target.value)} type="text" className={'form-control'} />
                        </div>
                                                                   
                    </form>
                </div>
            </div>
            <div className='flex px-4'>
                <button type="button"
                    className="flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    onClick={() => onSubmit()}>
                    {t('account.forgotPasswordForm.action1')}
                </button>   
            </div>
            <div className='mt-1'>
                <p>{message}</p>
            </div>     
            <div className='mt-1 px-4 py-1 text-gray-500 text-xs text-center'>
                <p>{t('account.forgotPasswordForm.note')}</p>
            </div>                    
            <div className="flex bg-gray-200 px-4 py-3 sm:px-6 flex-col sm:flex-row-reverse space-y-1">
                <button type="button"
                    className="flex-1 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:flex-2 sm:ml-3 sm:text-sm"
                    onClick={() => goLogin()}>
                    {t('account.forgotPasswordForm.action2')}
                </button>             
                <button 
                    type="button"
                    onClick={() => onClose()}
                    className="flex-1 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-2 sm:text-sm">
                    {t('account.forgotPasswordForm.action3')}
                </button>
            </div>
        </div>
    );
}

export { ForgotPasswordForm }