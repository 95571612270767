import React, { useState, useEffect } from 'react';
import { BoxDescription } from './BoxDescription';
import { EmployeeList} from './EmployeeList';
import { TopServiceList } from './TopServiceList';
import { SlideshowList } from './SlideshowList';
import { OpenTimeList } from './OpenTimeList';
import { ProductList } from './ProductList';
import { ArticleList } from './ArticleList';
import { BoxContact} from './BoxContact';
import { FacebookFeed, SimpleMap, InstagramFeed, YoutubeVideo} from '../../_components';
import { SiteSurveyBox } from '../../_components/SiteSurvey/SiteSurveyBox';
import { SiteProductPreview } from '../../_components/SiteProductPreview';
import { appSiteService, accountService } from '../../_services';
import { Responsive, WidthProvider } from 'react-grid-layout';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function PageBoxList(props) 
{        
    const ResponsiveGridLayout = WidthProvider(Responsive);
    const [layouts, setLayouts] = useState(null)
    const [loadingLayouts, setLoadingLayouts] = useState(true)

    useEffect(() => {    
        setLoadingLayouts(true)
        appSiteService.getPageLayouts(props.appSiteId, props.sitePageId).then((x) => { 
            setLoadingLayouts(false)       
            setLayouts(x)
        });
    }, [props.appSiteId, props.sitePageId]);  

    return (
        <>  
            {!props.pageBoxes && loadingLayouts &&               
            <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-700 rounded"></div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-700 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>}

            {!loadingLayouts && layouts && props.pageBoxes &&
            <ResponsiveGridLayout className="layout"
                isDraggable={false}
                isResizable={false}
                margin={[0,0]}
                layouts={layouts}
                autoSize={true}
                breakpoints={{lg: 1200, sm: 768, xs: 0}}
                cols={{lg: 12, sm: 6, xs: 4}}>
                {props.pageBoxes && props.pageBoxes.map(pageBox =>                                                    
                <div 
                    className={`flex items-center ${(pageBox.loginRequest && accountService.userValue == null ? 'blur-lg' : '')} page-box bg-cover bg-center`}                    
                    key={pageBox.pageBoxId.toString()} 
                    style={{backgroundImage: `url(${baseImageUrl+((parseInt(pageBox.boxType) !== 8 && parseInt(pageBox.boxType) !== 9) ? pageBox.imageUrl : '')})`, 
                        backgroundColor: (pageBox.sortId < 0 ? pageBox.boxColor : '')}}>
                    <div style={{backgroundColor: (pageBox.sortId > 0 ? pageBox.boxColor : '')}} 
                        className={`w-full p-${pageBox.boxPadding} m-${pageBox.boxMargin} ${(pageBox.sortId === 2 ? 'rounded-full' : (pageBox.sortId === 3 ? 'rounded-xl' : 'rounded'))}
                            ${((pageBox.landingPageId > 0 || pageBox.siteSurveyId > 0) ? 'transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:brightness-110 duration-300':'')}`}>

                        {pageBox.boxType && (parseInt(pageBox.boxType) === 8 || parseInt(pageBox.boxType) === 9) &&
                        <div 
                            style={{backgroundColor: (pageBox.sortId > 0 ? pageBox.boxColor : '')}} 
                            className={`p-${pageBox.boxPadding} m-${pageBox.boxMargin} ${(pageBox.sortId === 2 ? 'rounded-full' : (pageBox.sortId === 3 ? 'rounded-xl' : 'rounded'))} ${(pageBox.landingPageId > 0 ? 'hover:scale-125 cursor-pointer' : '')}`}
                            onClick={() => {
                                if (pageBox.landingPageId > 0)
                                    props.changePage(pageBox.landingPageId)
                            }}>
                            <img className="object-cover" src={baseImageUrl+pageBox.imageUrl} alt={pageBox.title} />                        
                        </div>} 
                        
                        {(pageBox.boxType && (parseInt(pageBox.boxType) === 1 || parseInt(pageBox.boxType) === 9)) &&
                        <BoxDescription 
                            boxPadding={pageBox.boxPadding}
                            boxMargin={pageBox.boxMargin}
                            title={pageBox.title} 
                            description={pageBox.description} 
                            shape={pageBox.sortId}
                            boxColor={pageBox.boxColor}
                            landingPageId={pageBox.landingPageId}
                            changePage={(pageId) => props.changePage(pageId)} />}                              
                            
                        {pageBox.boxType && parseInt(pageBox.boxType) === 2 &&
                        <TopServiceList appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} />}    
                        {pageBox.boxType && parseInt(pageBox.boxType) === 3 &&
                        <EmployeeList appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} />}    
                        {pageBox.boxType && parseInt(pageBox.boxType) === 4 &&
                        <OpenTimeList appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} />}                        
                        {pageBox.boxType && parseInt(pageBox.boxType) === 5 &&
                        <ProductList appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} productTypeId={0} />} 
                        {pageBox.boxType && parseInt(pageBox.boxType) === 6 &&
                        <ArticleList appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} />}
                        {pageBox.boxType && parseInt(pageBox.boxType) === 7 &&
                        <SimpleMap appSiteId={pageBox.appSiteId} boxLatitude={pageBox.boxLatitude} boxLongitude={pageBox.boxLongitude} />} 
                        {pageBox.boxType && parseInt(pageBox.boxType) === 10 &&
                        <BoxContact title={pageBox.title} appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} />} 
                        {pageBox.boxType && parseInt(pageBox.boxType) === 11 &&
                        <FacebookFeed feedUrl={pageBox.boxEmail} title={pageBox.title} boxColor={pageBox.boxColor} />}  
                        {pageBox.boxType && parseInt(pageBox.boxType) === 12 &&
                        <InstagramFeed userName={pageBox.boxEmail} className="Feed" classNameLoading="Loading" limit="8" />}  
                        {pageBox.boxType && parseInt(pageBox.boxType) === 13 &&
                        <YoutubeVideo videoUrl={pageBox.boxEmail} title={pageBox.title} boxColor={pageBox.boxColor} />}  
                        {pageBox.boxType && parseInt(pageBox.boxType) === 14 &&
                        <SiteSurveyBox appSiteId={pageBox.appSiteId} siteSurveyId={pageBox.siteSurveyId} userSurveyId={0} surveyStepId={1} pageBox={pageBox} />}  
                        {pageBox.boxType && parseInt(pageBox.boxType) === 15 &&
                        <SlideshowList appSiteId={pageBox.appSiteId} sitePageId={pageBox.sitePageId} pageBoxId={pageBox.pageBoxId} />}    
                        {pageBox.boxType && parseInt(pageBox.boxType) === 16 &&
                        <iframe className='w-full' height={pageBox.boxLatitude} title={pageBox.titleUrl} src={pageBox.boxEmail}></iframe>}
                        {pageBox.boxType && parseInt(pageBox.boxType) === 17 && pageBox.siteProductId > 0 &&
                        <SiteProductPreview appSiteId={pageBox.appSiteId} siteProductId={pageBox.siteProductId} template={pageBox.boxTemplate}
                            landingPageId={pageBox.landingPageId}
                            changePage={(pageId) => props.changePage(pageId)} />}  
                    </div>
                </div>                
                )}                                              
            </ResponsiveGridLayout>}  
        </>
    );
}

export { PageBoxList };