import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { appSiteService } from '../../_services'

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function SlideshowList ({appSiteId,sitePageId,pageBoxId})
{
    const [slideshows,setSlideshows] = useState([])
    const [currentSlide, setCurrentSlide] = useState(0)
    const [paused, setPaused] = useState(false)
    
    useEffect(() => {
        appSiteService.getTopServicesOfBox(appSiteId, sitePageId, pageBoxId)
            .then((_slideshows) => {
                setSlideshows(_slideshows.totalCount > 0 ? _slideshows.result : []);                
            })
            .catch(() => {});                
    },[appSiteId, sitePageId, pageBoxId])    
        
    useEffect(() => {            
        setTimeout(() => {
            if(slideshows && paused === false) {
                nextSlide()
                //let newSlide = currentSlide === slideshows.length - 1 ? 0 : currentSlide + 1
                //setCurrentSlide(newSlide)
            }
            }, 3000)
    },[slideshows, paused, currentSlide])

    const nextSlide = () => {
        let newSlide =
            currentSlide === slideshows.length - 1
            ? 0
            : currentSlide + 1;
        setCurrentSlide(newSlide);
    };
    
    const prevSlide = () => {
        let newSlide =
            currentSlide === 0
            ? slideshows.length - 1
            : currentSlide - 1;
        setCurrentSlide(newSlide);
    };
             
    return (
        <div className="mt-8 mb-8">
            <div className="h-72 flex overflow-hidden relative">
                {slideshows && slideshows.map((slideshow, index) =>                
                <img 
                    key={index} 
                    src={baseImageUrl+slideshow.imageUrl} 
                    className={ index === currentSlide ? "block w-full h-auto object-cover" : "hidden" }
                    alt={slideshow.title} />                                            
                )}
            </div>                
            <div className="absolute w-full flex justify-center bottom-0">
            {slideshows && slideshows.map((element, index) => {
                return (
                <div
                    className={
                    index === currentSlide
                        ? "h-2 w-2 bg-blue-700 rounded-full mx-2 mb-2 cursor-pointer"
                        : "h-2 w-2 bg-white rounded-full mx-2 mb-2 cursor-pointer"
                    }
                    key={index}
                    onClick={() => setCurrentSlide(index)}
                ></div>
                );
            })}
            </div>
        </div>
    );
}

export { SlideshowList };