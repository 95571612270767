import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

function SiteSurveyBoxAnswerButton ({ questionAnswer, stepQuestion, surveyStep, countQty, changeActive, initialValue, userAnswers }) {
    const [isActive, setIsActive] = useState(initialValue | false);

    useEffect(() => {
        if (userAnswers == null)
            return;

        setIsActive(false)
        userAnswers.forEach(_userAnswer => {
            if (_userAnswer.surveyStepId === questionAnswer.surveyStepId &&
                _userAnswer.stepQuestionId === questionAnswer.stepQuestionId &&
                _userAnswer.questionAnswerId === questionAnswer.questionAnswerId) {
                setIsActive(true)
            }
        });
    }, [questionAnswer, stepQuestion, userAnswers])

    const handleChangeActive = () => {
        //setIsActive(!isActive)
        changeActive(!isActive, 1, false)
    }

    return (
        <div onClick={() => handleChangeActive(questionAnswer)}
            className={`block px-8 py-2 cursor-pointer border border-bottom-xl rounded ` + 
                (questionAnswer.endSurvey ? 'border-2 ' : '') + 
                (isActive ? 'drop-shadow-2xl shadow-2xl' : '') +
                ` hover:shadow-2xl hover:border-transparent`}
                style={{backgroundColor: questionAnswer.boxColor}}>
            <div className="inline-flex items-center">                
                <div className="ml-2 cursor-pointer">
                    {questionAnswer.answerText && parse(questionAnswer.answerText)}
                </div>
            </div>
            {questionAnswer.price > 0 && <><br /><b>€ {questionAnswer.price}</b></>}            
        </div>
    );
}

export { SiteSurveyBoxAnswerButton };