import React, { useState } from 'react';
import { Dialog } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/outline';
import { SearchDialogResult } from './SearchDialogResult';

function SearchDialog ({ appSiteId, onClose, onPageSelect }) {

    const [isOpen, setIsOpen] = useState(false);    

    return (
        <>
        <button onClick={() => setIsOpen(true)} className='px-4 py-2 text-sm font-medium text-white bg-black rounded-lg bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
          <span className="relative inline-block">
            <SearchIcon className="h-6 w-6 text-white" aria-hidden="true" />            
          </span>          
        </button>

        <Dialog as="div" className="fixed z-10 inset-0" onClose={() => setIsOpen(false)} open={isOpen}>
          <div className="static block items-end justify-center min-h-screen pt-4 pb-4 text-center sm:p-0">
            <Dialog.Overlay onClick={() => setIsOpen(false)} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />                        
            <div className="absolute left-2 right-2 top-7 bottom-7 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">

              <div className="block relative mx-auto w-full md:w-1/2 h-full rounded-xl flex-grow overflow-hidden">

                <div className='absolute md:mt-8 top-0 right-0 z-10 text-gray-400 p-2 cursor-pointer' onClick={() => setIsOpen(false)}>                                            
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
 
                <SearchDialogResult appSiteId={appSiteId} />

              </div>
            </div>

          </div>
        </Dialog>                    
        </>
    )    
}

export { SearchDialog };