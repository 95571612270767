import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { accountService } from './_services';
import './i18n';

accountService.refreshToken().finally(startApp);

function startApp() { 
  //  ReactDOM.render(
  //    <Router history={history}>
  //     <App />
  //    </Router>,
  //    document.getElementById('root')
  //  ); 

  ReactDOM.render(<App />, document.getElementById('root'));           
  //serviceWorker.register();
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
