import React, {useState,useEffect} from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { appSiteService,languageService } from '../_services';
import { ProfileModal } from '../_components/Account/ProfileModal'
import { ShopCart } from "./Shop/ShopCart";
import { SearchDialog } from './SearchDialog/SearchDialog';
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function NavMulti({appSite,navAlign,navPosition,navColor,activeProfileModal,activeShopModal,handleChangePage,stripePublicKey}) 
{    
  //const history = useHistory();
  const [navPos,setNavPosition] = useState('');
  const [navAli,setNavAlign] = useState('');
  const [pages, setPages] = useState([]);

  const [navHidden, setNavHidden] = useState(window.innerWidth <= 768)

    useEffect(() => {
      // Add event listener
      window.addEventListener("resize", () => setNavHidden(window.innerWidth <= 768));
        
    }, [])

  useEffect(() => {
    // Position
    var _navPos = 'header-fixed-top';
    if (navPosition === -1) _navPos = 'hidden';
    if (navPosition === 2) _navPos = 'header-top';
    if (navPosition === 3) _navPos = 'header-fixed-bottom';
    // Align
    var _navAlign = 'justify-start';
    if (navAlign === 1) _navAlign = 'justify-start';
    if (navAlign === 2) _navAlign = 'justify-center';
    if (navAlign === 3) _navAlign = 'justify-end';
    // Set Position and Align based on pge
    setNavAlign(_navAlign)
    setNavPosition(_navPos)
    // Subscribe language change
    languageService.lang.subscribe(_lang => getPagesOfAppSite())
    // Get Pages of site
    getPagesOfAppSite(appSite.appSiteId)

  },[appSite,navPosition,navAlign])

  const getPagesOfAppSite = (appSiteId) => {
    if (appSiteId && appSiteId > 0)
      appSiteService.getNavPagesOfAppSite(appSiteId)
          .then((_pages) => setPages(_pages))                
  }
    
  return (
    <>
    <div className={`shadow-xl ${navPos}`}>
      <div style={{backgroundColor: navColor}} className='relative'>
        <Navbar expand="lg" sticky="top">
          <Navbar.Brand
            href={`/`}                 
            //onClick={() => handleChangePage(1)}
            >
            {appSite.companyLogo && 
            <Image className="company-logo h-12 md:h-16" src={baseImageUrl+appSite.companyLogo}></Image>                                    
            }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className={`w-full ${navAli}`}>
              {pages && pages.filter(p => p.sitePageId > 1).map(page => 
                  <div key={page.sitePageId} className="dropdown text-left">
                    <Nav.Link key={page.sitePageId}    
                      href={`/${page.sitePageId}`}                 
                      //onClick={() => handleChangePage(page.sitePageId)}
                      //onClick={() => history.push(`/${page.sitePageId}`)}
                      >
                      <div className="mt-2">
                      {page.titleNav && parse(page.titleNav)}
                      </div>
                    </Nav.Link>
                    {page.subSitePages.length > 0 &&                                                 
                    <ul style={{backgroundColor: navColor}}
                      className="dropdown-menu space-y-2">
                      {page.subSitePages.map(subPage =>
                        <li 
                          onClick={() => handleChangePage(subPage.sitePageId)}
                          key={subPage.sitePageId} className="rounded-xl border pt-3 ml-2 mr-2"
                          >
                          <div className="text-sm ml-2">{subPage.titleNav && parse(subPage.titleNav)}</div>
                          {/* <a href={'/'+subPage.sitePageId}>
                          </a> */}
                        </li>    
                      )}
                    </ul>}
                  </div>
              )}                                                                                                
            </Nav>
            <Nav>
                {!navHidden &&
                <div className="text-center md:flex space-y-2 md:space-x-2 md:space-y-0">
                  {appSite.shopEnabled  && 
                  <ShopCart appSiteId={appSite.appSiteId} 
                    initialCart={[]} 
                    stripePublicKey={stripePublicKey}
                    activeShopModal={activeShopModal}
                    optionalPay={appSite.optionalPay} />}
                  {appSite.loginEnabled && 
                  <ProfileModal 
                    activeProfileModal={(activeProfileModal)} />}
                  {/* <SearchDialog appSiteId={appSite.appSiteId} /> */}
                </div>}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>     

    </div>
    {navPosition === 1 && 
    <div className="pt-16"></div>}      
    </>
  );
}

export { NavMulti };
