import React from 'react';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import { appSiteService } from '../../_services';
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

class ArticleList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            articles: null,
            appSiteId: this.props.appSiteId,
            sitePageId: this.props.sitePageId,
            pageBoxId: this.props.pageBoxId
        }        
    }

    componentDidMount() {
        this.getArticles(this.props.appSiteId, this.props.sitePageId, this.props.pageBoxId, 0);
    }

    getArticles(appSiteId, sitePageId, pageBoxId, categoryId) {            
        appSiteService.getArticlesOfBox(appSiteId, sitePageId, pageBoxId, categoryId)
            .then((_articles) => {
                this.setState({ articles: (_articles.totalCount > 0 ? _articles.result : []) });                                                                
            })
            .catch(() => {});        
    }
    
    render() {
        return (
            <div className="description">
                <Container>                
                    <Row>
                    {this.state.articles && this.state.articles.map(article =>                                    
                        <Col sm={parseInt(article.cardSize)} key={article.articleId}>
                            <Card className="mart2">
                                <Card.Body>                            
                                    <Card.Title>
                                        {article.title}
                                    </Card.Title>                                                                                                               
                                    <Row className="justify-content-md-center">
                                        <Col sm={4}>
                                            <Image src={baseImageUrl+article.imageUrl} roundedCircle fluid />
                                        </Col>
                                        <Col sm={4}>
                                            {article.description && parse(article.description)}
                                        </Col>
                                        <Col sm={4}>
                                            {article.markdown && parse(article.markdown)}
                                            <hr />
                                            {article.html && parse(article.html)}
                                        </Col>
                                    </Row>      
                                </Card.Body>
                            </Card>    
                        </Col>                                        
                    )}                    
                    {!this.state.articles &&                
                        <Col className="text-center">
                            <span className="spinner-border spinner-border-lg align-center"></span>
                        </Col>
                    }             
                    </Row>   
                </Container>
            </div>            
        );
    }

}

export { ArticleList };