import React, {useState,useEffect} from 'react';
import { shopService } from '../../_services/shop.service';
import { accountService } from '../../_services/account.service'
import { AppContext } from '../../_data/shop/state'
import { OrderTypeSelect } from './OrderTypeSelect';
import { useTranslation } from 'react-i18next'

function RegistryDetail({appSiteId, userEmail, onRegistryConfirm}) 
{
    const [registryId, setRegistryId] = useState(0)
    const [registryName, setName] = useState(accountService.userValue ? accountService.userValue.firstName + ' ' + accountService.userValue.lastName : '') 
    const [registryPhone, setPhone] = useState('')
    const [registryAddress, setAddress] = useState('')
    const [registryCity, setCity] = useState('')
    const [registryNote, setNote] = useState('')

    const { state, saveOrder } = React.useContext(AppContext);

    const { t } = useTranslation();

    const [orderType, setOrderType] = useState(state.order.orderType)

    const [isUpdated, setIsUpdated] = useState(false)

    useEffect(() => {
        if (userEmail)
            shopService.getRegistryByEmail(userEmail)
                .then((registry) => {
                    if (!registry.hasErrors && registry.registryId > 0) {
                        setRegistryId(registry.registryId)
                        setName(registry.name)
                        setPhone(registry.phone)
                        setAddress(registry.address)
                        setCity(registry.city)
                        setNote(registry.note)
                    }
                })
    },[appSiteId, userEmail])

    const handleOrderTypeChange = (_orderTypeId) => {
        if (_orderTypeId > 0) {
            saveOrder({...state.order, orderType: _orderTypeId})
            setOrderType(_orderTypeId)    
        }
    }

    const handleRegistrySave = () => {
        var registry = {
            appSiteId: appSiteId,
            registryId: registryId,
            email: userEmail,
            name: registryName,
            phone: registryPhone,
            address: registryAddress,
            city: registryCity,
            note: registryNote
        }

        shopService.createOrUpdateRegistry(registry)
            .then((_registry) => {
                if (!_registry.hasErrors) {
                    setRegistryId(_registry.registryId)
                    setName(_registry.name)
                    setPhone(_registry.phone)
                    setAddress(_registry.address)
                    setCity(_registry.city)
                    setNote(_registry.note)

                    registry.registryId = _registry.registryId;
                    
                    setIsUpdated(true)

                    onRegistryConfirm(_registry)
                }
            })
    }

    return (
        <div>
            <div className="form-group">
                <input name="registryName" readOnly={isUpdated} placeholder={t('shop.registry.name')} type="text" value={registryName} onChange={(e) => setName(e.target.value)} className='form-control' />
            </div>
            <div className="form-group">
                <input name="registryPhone" readOnly={isUpdated} placeholder={t('shop.registry.phone')} type="text" value={registryPhone} onChange={(e) => setPhone(e.target.value)} className='form-control' />
            </div>

            {/* <OrderTypeSelect orderType={orderType} onChange={(_orderType) => handleOrderTypeChange(_orderType)} /> */}
            
            {orderType === 1 &&
            <>
            <div className="form-group">
                <input name="registryAddress" readOnly={isUpdated} placeholder={t('shop.registry.address')} type="text" value={registryAddress} onChange={(e) => setAddress(e.target.value)} className='form-control' />
            </div>
            <div className="form-group">
                <input name="registryCity" readOnly={isUpdated} placeholder={t('shop.registry.city')} type="text" value={registryCity} onChange={(e) => setCity(e.target.value)} className='form-control' />
            </div>
            </>}
            <div className="form-group">
                <input name="registryNote" readOnly={isUpdated} placeholder={t('shop.registry.registryNote')} type="text" value={registryNote} onChange={(e) => setNote(e.target.value)} className='form-control' />
            </div>

            {!isUpdated && 
            <>
                <div className='flex mt-2'>
                    <button type="button"
                        onClick={() => handleRegistrySave()}
                        className="flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
                            {t('shop.registry.action1')}
                    </button>
                </div>

                <div className='mt-1 text-gray-500 text-xs text-center'>
                    <p>{t('shop.registry.note')}</p>
                </div>
            </>}
        </div>
    )
}

export { RegistryDetail }