//import { config } from 'config';
import { fetchWrapper } from '../_helpers';

//import { config } from '../config.json'
// export const config = require("../config.json");
// const baseUrl = `${config.REACT_APP_API_URL}/PublicAppSite`;
const baseUrl = `${process.env.REACT_APP_API_URL}/PublicAppSite`;

export const surveyService = {
    getStepsOfSurvey,
    getQuestionsOfStep,
    getAnswersOfQuestion,
    getOutcomeTypesOfAppSite,
    getSiteSurveyById,
    getSurveyStepById,
    getStepQuestionById,
    getQuestionAnswerById,
    getOutcomeTypeById,
    createUserSurvey    
};

function getStepsOfSurvey(appSiteId, siteSurveyId) {
    return fetchWrapper.post(`${baseUrl}/SearchStepsOfSurvey/${appSiteId}/${siteSurveyId}`, { search: '', page: 0, count: 0});
}

function getQuestionsOfStep(appSiteId, siteSurveyId, surveyStepId) {
    return fetchWrapper.post(`${baseUrl}/SearchQuestionsOfStep/${appSiteId}/${siteSurveyId}/${surveyStepId}`, { search: '', page: 0, count: 0});
}

function getAnswersOfQuestion(appSiteId, siteSurveyId, surveyStepId, stepQuestionId) {
    return fetchWrapper.post(`${baseUrl}/SearchAnswersOfQuestion/${appSiteId}/${siteSurveyId}/${surveyStepId}/${stepQuestionId}`, { search: '', page: 0, count: 0});
}

function getOutcomeTypesOfAppSite(appSiteId, search, page, count) {
    return fetchWrapper.post(`${baseUrl}/SearchOutcomeTypesOfAppSite/${appSiteId}`, { search: search, page: page, count: count });
}

function getSiteSurveyById(appSiteId, siteSurveyId) {
    return fetchWrapper.get(`${baseUrl}/GetSiteSurvey/${appSiteId}/${siteSurveyId}`);
}

function getSurveyStepById(appSiteId, siteSurveyId, surveyStepId) {
    return fetchWrapper.get(`${baseUrl}/GetSurveyStep/${appSiteId}/${siteSurveyId}/${surveyStepId}`);
}

function getStepQuestionById(appSiteId, siteSurveyId, surveyStepId, stepQuestionId) {
    return fetchWrapper.get(`${baseUrl}/GetStepQuestion/${appSiteId}/${siteSurveyId}/${surveyStepId}/${stepQuestionId}`);
}

function getQuestionAnswerById(appSiteId, siteSurveyId, surveyStepId, stepQuestionId, questionAnswerId) {
    return fetchWrapper.get(`${baseUrl}/GetQuestionAnswer/${appSiteId}/${siteSurveyId}/${surveyStepId}/${stepQuestionId}/${questionAnswerId}`);
}

function getOutcomeTypeById(appSiteId, outcomeTypeId) {
    return fetchWrapper.get(`${baseUrl}/GetUserSurvey/${appSiteId}/${outcomeTypeId}`);
}

function createUserSurvey(userSurvey, userAnswers) {
    return fetchWrapper.post(`${baseUrl}/CreateUserSurvey`, { userSurvey: userSurvey, userAnswers: userAnswers });
}

