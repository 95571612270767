import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { alertService, AlertType } from '../_services';
import { history } from '../_helpers';

const propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool
};

const defaultProps = {
    id: 'default-alert',
    fade: true
};

function Alert({ id, fade }) {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        // subscribe to new alert notifications
        const subscription = alertService.onAlert(id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    setAlerts(alerts => {
                        // filter out alerts without 'keepAfterRouteChange' flag
                        const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

                        // remove 'keepAfterRouteChange' flag on the rest
                        filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                        return filteredAlerts;
                    });
                } else {
                    // add alert to array
                    setAlerts(alerts => ([...alerts, alert]));

                    // auto close alert if required
                    if (alert.autoClose) {
                        setTimeout(() => removeAlert(alert), 5000);
                    }
                }
            });

        // clear alerts on location change
        const historyUnlisten = history.listen(({ pathname }) => {
            // don't clear if pathname has trailing slash because this will be auto redirected again
            if (pathname.endsWith('/')) return;

            alertService.clear(id);
        });

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, []);

    function removeAlert(alert) {
        if (fade) {
            // fade out alert
            const alertWithFade = { ...alert, fade: true };
            setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));

            // remove alert after faded out
            setTimeout(() => {
                setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
            }, 250);
        } else {
            // remove alert
            setAlerts(alerts => alerts.filter(x => x !== alert));
        }
    }
    
    const alertTypeClass = {
        [AlertType.Success]: 'green',
        [AlertType.Error]: 'red',
        [AlertType.Info]: 'blue',
        [AlertType.Warning]: 'orange'
    }

    function cssClasses(alert) {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable'];
                        
        if (alert.fade) 
            classes.push('fade');        

        return classes.join(' ');
    }

    if (!alerts.length) return null;

    return (
        <div className='container-alert flex items-center w-full'>
            <div className="w-96 mx-auto">
                {alerts.map((alert, index) =>
                <div key={index}>

                    {alert.type === AlertType.Success &&
                    <div className={`bg-green-700 border border-green-600 text-white ${cssClasses(alert)} p-4 m-4 flex items-center text-center space-x-2 rounded relative`}>
                        <button className="close" onClick={() => removeAlert(alert)}>&times;</button>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg> */}
                        <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
                    </div>}                    

                    {alert.type === AlertType.Error &&
                    <div className={`bg-red-300 border border-red-700 text-red-900 ${cssClasses(alert)} p-4 m-4 rounded relative`}>
                        <button className="close" onClick={() => removeAlert(alert)}>&times;</button>
                        <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
                    </div>}

                    {alert.type === AlertType.Info &&
                    <div className={`bg-blue-300 border border-blue-700 text-blue-900 ${cssClasses(alert)} p-4 m-4 rounded relative`}>
                        <button className="close" onClick={() => removeAlert(alert)}>&times;</button>
                        <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
                    </div>}

                    {alert.type === AlertType.Warning &&
                    <div className={`bg-orange-300 border border-orange-700 text-orange-900 ${cssClasses(alert)} p-4 m-4 rounded relative`}>
                        <button className="close" onClick={() => removeAlert(alert)}>&times;</button>
                        <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
                    </div>}

                </div>
                )}
            </div>
        </div>
    );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };