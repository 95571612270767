import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from './_helpers';
import { Home } from './Home/Index';
import { Alert } from './_components/Alert';
import './App.css';
import './megamenu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppContextProvider } from './_data/shop/state'

function App() {
  return (
    <div className="App">      
      <AppContextProvider>
          <Router history={history}>
            <Switch>                                      
                <Route path="/:pageId" component={Home} />
                <Redirect from="*" to="/1" />
            </Switch>    
          </Router>             
          <Alert />
      </AppContextProvider>
    </div>
  );
}

export default App;
