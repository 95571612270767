import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function SiteSurveyBoxAnswerCheck ({ questionAnswer, stepQuestion, surveyStep, countQty, changeActive, initialValue, userAnswers }) {
    
    const [isActive, setIsActive] = useState(initialValue | false);
    const [qty, setQty] = useState(0)  

    useEffect(() => {
        if (userAnswers == null)
            return;

        setIsActive(false)
        userAnswers.forEach(_userAnswer => {
            if (_userAnswer.surveyStepId === questionAnswer.surveyStepId &&
                _userAnswer.stepQuestionId === questionAnswer.stepQuestionId &&
                _userAnswer.questionAnswerId === questionAnswer.questionAnswerId) {
                setIsActive(true)
                setQty(_userAnswer.quantity)
            }
        });
    }, [questionAnswer, stepQuestion, userAnswers])

    const handleAddAnswer = () => {           
        var newQty = isActive ? qty + 1 : 1;
        if ((questionAnswer.maxValue > 0 && newQty > questionAnswer.maxValue) || 
            (surveyStep.maxAnswers > 0 && (countQty + 1) > surveyStep.maxAnswers))
            return false;
            
        setQty(newQty)
        changeActive(true, newQty, !isActive)        
    }

    const handleRemoveAnswer = () => {
        var newQty = qty - 1;
        setQty(newQty)
        if (newQty === 0) 
            changeActive(false, newQty, false)
        else
            changeActive(true, newQty, false)        
    }

    return (
        <>
            <div className={`block px-2 py-2 cursor-pointer rounded-xl ` + (questionAnswer.endSurvey ? 'border-green-2 ' : '') + 
                    (isActive ? 'shadow-2xl' : '') +
                    ` hover:shadow-2xl hover:border-transparent`}
                style={{backgroundColor: questionAnswer.boxColor}}>
                <div className="flex items-center flex-grow">                            
                    
                    <div className='flex pr-1' onClick={() => handleAddAnswer()}>
                        {!isActive && 
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>}
                        {isActive &&
                        <div className='bg-green-700 rounded-full p-1'>
                            <span className='pl-2 pr-2 text-white'>{qty}</span>
                        </div>
                        // <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        //     <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        // </svg>
                        }            
                    </div>
                    <div className="flex-1 flex items-center">
                        
                        {parseInt(questionAnswer.answerType) === 4 && questionAnswer.siteProduct &&
                        <div className='flex rounded overflow-hidden w-full' onClick={() => handleAddAnswer()}>
                            {questionAnswer.siteProduct.imageUrl != null && questionAnswer.siteProduct.imageUrl !== '' &&
                            <div className="flex-none w-24 relative ">
                                <img className="absolute inset-0 w-full h-full object-cover rounded-full" src={baseImageUrl+questionAnswer.siteProduct.imageUrl} alt={questionAnswer.siteProduct.code} />
                            </div>}
                            <div className="flex-1 md:w-48 p-1 leading-tight text-black">
                                <div className='leading-tight font-normal'>{questionAnswer.siteProduct.code}</div>                                
                                {questionAnswer.siteProduct.description &&
                                <div className='leading-tight text-sm h-12'>
                                    {parse(questionAnswer.siteProduct.description)}
                                </div>}
                            </div>                                
                            {(questionAnswer.price > 0  || (questionAnswer.siteProduct && questionAnswer.siteProduct.price > 0)) &&
                            <div className="flex items-center">
                                <div className="bg-white items-center rounded-xl p-1 uppercase tracking-wide font-medium text-indigo-500">
                                    {questionAnswer.price > 0 &&
                                    <span className='text-gray-700 text-sm'>+{questionAnswer.price} €</span>}
                                    {questionAnswer.siteProduct && questionAnswer.siteProduct.price > 0 &&
                                    <span className='text-gray-700 text-sm'>+{questionAnswer.siteProduct.price} €</span>}
                                </div>
                            </div>}                                  
                        </div>}

                        {parseInt(questionAnswer.answerType) === 1 &&                         
                        <div className="flex-1 rounded overflow-hidden w-full" onClick={() => handleAddAnswer()}>
                            {questionAnswer.answerText && parse(questionAnswer.answerText)}
                        </div>}                        

                        {questionAnswer.price > 0 && 
                        <div className="flex-none">
                            <span className='text-gray-700 text-sm'>+{questionAnswer.price} €</span>
                        </div>}            

                        {isActive &&
                        <div className='flex-none bg-red-500 rounded-full p-1' onClick={() => handleRemoveAnswer()}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </span>
                        </div>}
                    </div>
                </div>            
            </div>            
        </>
    );
}

export { SiteSurveyBoxAnswerCheck };