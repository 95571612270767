import React from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

const baseImageUrl = `${process.env.REACT_APP_STORAGE_URL}/`;

function Slide(props) {

    const position = props.sitePage?.logoPosition ?? 1;
    const navPosition = props.sitePage?.navPosition ?? 1; 
    
    return (
        <div className={`container slide-top ${navPosition === 1 ? '' : ''}`}>
            {props.sitePage && props.appSite && 
            <Row>
                {(position === 1 || position === 2) && 
                <Col sm={position === 1 ? 12 : 6} className="mx-auto">
                    <Image className="slide-logo" src={baseImageUrl+props.appSite.companyLogo}></Image>
                </Col>}
                <Col sm={(position === 2 || position === 4) ? 6 : 12}>
                    {props.sitePage.title ? parse(props.sitePage.title) : ''}
                    {props.sitePage.slideText ? parse(props.sitePage.slideText) : ''}
                </Col>
                {(position === 3 || position === 4) && <Col sm={position === 3 ? 12 : 6} className="mx-auto">
                    <Image className="slide-logo" src={baseImageUrl+props.appSite.companyLogo}></Image>
                </Col>}
            </Row>            
            }            
        </div>                          
    );
}

export { Slide };
