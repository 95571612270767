import { fetchWrapper } from '../_helpers';
import { languageService } from './language.service'
//import { config } from '../config.json'
// export const config = require("../config.json");
// const baseUrl = `${config.REACT_APP_API_URL}/PublicAppSite`;
const baseUrl = `${process.env.REACT_APP_API_URL}/PublicAppSite`;

export const appSiteService = {
    getAppSiteById,
    getPageById,
    getPageByTitle,
    getPagesOfAppSite,
    searchOnSite,
    getNavPagesOfAppSite,
    getBoxesOfPage,
    getEmployeesOfBox,
    getTopServicesOfBox,
    getOpenTimesOfBox,
    getProductTypes,
    getProductsOfBox,
    getCategories,
    getArticlesOfBox,
    getPageLayouts
};

function getAppSiteById(appSiteId) {
    return fetchWrapper.get(`${baseUrl}/GetAppSite/${appSiteId}/${languageService.langValue}`);
}

function getPageById(appSiteId, pageId) {
    return fetchWrapper.get(`${baseUrl}/GetSitePage/${appSiteId}/${pageId}/${languageService.langValue}`);
}

function getPageByTitle(appSiteId, titleUrl) {
    return fetchWrapper.post(`${baseUrl}/GetSitePageByTitle/${appSiteId}`, { titleUrl: titleUrl, lang: languageService.langValue });
}

function getPagesOfAppSite(appSiteId) {
    return fetchWrapper.get(`${baseUrl}/SearchPagesOfAppSite/${appSiteId}/${languageService.langValue}`);
}

function searchOnSite(appSiteId, search) {
    return fetchWrapper.post(`${baseUrl}/SearchOnSite/${appSiteId}`, { search });
}

function getNavPagesOfAppSite(appSiteId) {
    return fetchWrapper.get(`${baseUrl}/SearchNavPagesOfAppSite/${appSiteId}/${languageService.langValue}`);
}

function getBoxesOfPage(appSiteId,sitePageId) {
    return fetchWrapper.get(`${baseUrl}/SearchBoxesOfPage/${appSiteId}/${sitePageId}/${languageService.langValue}`);
}

function getEmployeesOfBox(appSiteId, sitePageId, pageBoxId) {
    return fetchWrapper.get(`${baseUrl}/SearchEmployeesOfBox/${appSiteId}/${sitePageId}/${pageBoxId}/${languageService.langValue}`);
}

function getTopServicesOfBox(appSiteId, sitePageId, pageBoxId) {
    return fetchWrapper.get(`${baseUrl}/SearchTopServicesOfBox/${appSiteId}/${sitePageId}/${pageBoxId}/${languageService.langValue}`);
}

function getOpenTimesOfBox(appSiteId, sitePageId, pageBoxId) {
    return fetchWrapper.get(`${baseUrl}/SearchOpenTimesOfBox/${appSiteId}/${sitePageId}/${pageBoxId}`);
}

function getProductTypes(appSiteId, sitePageId, pageBoxId) {
    return fetchWrapper.get(`${baseUrl}/SearchProductTypes/${appSiteId}/${sitePageId}/${pageBoxId}`);
}

function getProductsOfBox(appSiteId, sitePageId, pageBoxId, typeId) {
    return fetchWrapper.get(`${baseUrl}/SearchProductsOfBox/${appSiteId}/${sitePageId}/${pageBoxId}/${typeId}/${languageService.langValue}`);
}

function getCategories() {
    return fetchWrapper.get(`${baseUrl}/SearchCategories/`);
}

function getArticlesOfBox(appSiteId, sitePageId, pageBoxId, typeId) {
    return fetchWrapper.get(`${baseUrl}/SearchArticlesOfBox/${appSiteId}/${sitePageId}/${pageBoxId}/${typeId}/${languageService.langValue}`);
}

function getPageLayouts(appSiteId, pageId) {
    return fetchWrapper.get(`${baseUrl}/GetPageLayouts/${appSiteId}/${pageId}`);
}