import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { appSiteService } from '../_services';
import { Image } from 'react-bootstrap';
import { AppContext } from '../_data/shop/state';

const AnyReactComponent = ({ imageUrl }) => <div><Image className="map-logo" src={imageUrl} /></div>;
 
function SimpleMap({appSiteId, boxLatitude, boxLongitude}) {  
  const [appSite, setAppSite] = useState()
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)

  const { state} = React.useContext(AppContext);

  useEffect(() => {
    appSiteService.getAppSiteById(appSiteId, state.languageCode)
      .then((_appSite) => { 
        setLatitude(boxLatitude !== 0 ? boxLatitude : _appSite.latitude)
        setLongitude(boxLongitude !== 0 ? boxLongitude : _appSite.longitude)
        setAppSite(_appSite)        
      });
  },[appSiteId, boxLatitude, boxLongitude])
  
  return (        
    <div className="simple-map">
          {appSite && latitude > 0 && latitude > 0 &&
            <div style={{ height: '50vh', width: '100%' }}>                
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDjhhyvk_-edBnpfSNu9IkZCc7203kM7no' }}
                defaultCenter={[appSite.latitude, appSite.longitude]}
                defaultZoom={17}
              >
                <AnyReactComponent
                  lat={latitude}
                  lng={longitude}
                  imageUrl='logo-map.png'
                />
              </GoogleMapReact>                
            </div>                
          }        
    </div>
  );
  
}
 
export { SimpleMap };